export const themeIds = {
    LIGHT: 1,
    DARK: 2,
};

const colors = {
    DENIM: "#F7F9FB",
    HAVELOCK_BLUE: "#546ABA",
    DODGER_BLUE: "#00A3FF",
    YELLOW_SEA: "#F59338",
    RADICAL_RED: "#FF2745",
    NEON_BLUE: "#00ffc2",
    VENICE_BLUE: "#14a2f2",
    BERMUDA_GREY: "#69809D",
    ROYAL_BLUE: "#1B57F0",
    CARIBBEAN_GREEN: "#00CF7E",
    LIME_GREEN: "#3FCC28",
    BLUE_WHALE: "#141416",
    REGAL_BLUE_1: "#1E344D",
    HAWKES_BLUE: "#F6F7FB",
    SUMMER_SKY: "#3196D3",
    REGAL_BLUE_2: "#494949",
    REGAL_BLUE_3: "#1E1E21",
    MAYA_BLUE: "#42B6F6",
    COLUMBIA_BLUE: "#BFE8FF",
    VIKING: "#4EB3D3",
    SUNSET_ORANGE: "#F3574F",
    WHITE_SMOKE: "#FFFFFF",
    WHITE_SMOKE2: "#F7F5F5",
    BERMUDA: "#7BCCC4",
    CERULEAN: "#0278AE",
    ROMANTIC: "#FFBF9B",
    MAGIC_MINT: "#A5ECD7",
    VIOLET_RED: "#D115BE",
    PELOROUS: "#287DCC",
    LIGHT_GREY: "#9A9A9A",
    LIGHT_GREY_2: "#D7D7D7",
    NERO: "#181818",
    SOLITUDE: "#F7F8F9",
    WHITE: "#FFFFFF",
    GAINSBORO: "#E7E7E7",
    KASHMIR_BLUE: "#546A8A",
    DARK_TANGERINE: "#F8A80E",
    SUNSHADE: "#F98442",
    MORTAR: "#5C5959",
    PACIFIC_BLUE: "#0044E3",
    CREAM: "#D6FEC4",
    PALE_GREEN: "#B9F79C",
    HONEYDEW: "#ECFFE9",
    FREE_SPEECH_GREEN: "#12C90E",
    ROSE_BUD: "#FF9A9A",
    DEEP_SKY_BLUE: "#00B1E9",
    LIGHT_GRADIENT_1: "#26CBFF",
    LIGHT_GRADIENT_2: "#176DFF",
    DARK_GRADIENT_1: "#26CBFF",
    DARK_GRADIENT_2: "#176DFF",
    HOVER_COLOR_1: "#E2DFDF",
    HOVER_COLOR_2: "#494949",
    ACTIVE_TAB_COLOR_LIGHT: "#E6F1FF",
    ACTIVE_TAB_COLOR_DARK: "#FFFFFF",
    TABLE_HEADER_COLOR_LIGHT: "#E6F1FF",
    TABLE_HEADER_COLOR_DARK: "#3C404D",
    TABLE_BORDER_COLOR_LIGHT: "#DEE1EB",
    TABLE_BORDER_COLOR_DARK: "#23262F",
    UNDER_LINE_COLOR_DARK: "#3C404D",
    UNDER_LINE_COLOR_LIGHT: "#BDC3D3",
    BORDER_COLOR_DARK: "#333642",
    BORDER_COLOR_LIGHT: "#777E90",
    MENU_BORDER_COLOR_DARK: "none",
    MENU_BORDER_COLOR_LIGHT: "#DEE1EB",
    WALLET_DROPDOWN_COLOR_LIGHT: "#FFFFFF",
    WALLET_DROPDOWN_COLOR_DARK: "#23262F",
    WALLET_HEADER_COLOR_LIGHT: "transparent",
    WALLET_HEADER_COLOR_DARK: "#353944",
    WALLET_BORDER_COLOR_DARK: "#3C404D",
    WALLET_BORDER_COLOR_LIGHT: "transparent",
    WALLET_USD_DENOM_COLOR_DARK: "#DEE1EB",
    WALLET_USD_DENOM_COLOR_LIGHT: "#23262F",
    WALLET_DARKTHEME_TEXT_COLOR_DARK: "#BDC3D3",
    WALLET_DARKTHEME_TEXT_COLOR_LIGHT: "#505665",
    PROFILE_HOVER_COLER_DARK: "#333642",
    PROFILE_HOVER_COLER_LIGHT: "#DEE1EB",
    PROFILE_ACTIVE_ITEM_DARK: "#505665",
    PROFILE_ACTIVE_ITEM_LIGHT: "#E1F2FF",
    USD_CURRENCY_BORDER_COLOR_DARK: "#BDBDBD",
    USD_CURRENCY_BORDER_COLOR_LIGHT: "#DEE1EB",
    USD_CURRENCY_TEXT_COLOR_DARK: "#E0E0E0",
    USD_CURRENCY_TEXT_COLOR_LIGHT: "#181818",
    CONTAINER_BACKGROUND_COLOR_DARK: "#23262F",
    CONTAINER_BACKGROUND_COLOR_LIGHT: "#F7F8F9",
    SLIDER_TEXT_BACKGROUND_COLOR_DARK: "#2e2e2e",
    SLIDER_TEXT_BACKGROUND_COLOR_LIGHT: "#e3e3e3",
    SCROLL_BAR_BACKGROUND_COLOR_DARK: "#505665",
    SCROLL_BAR_BACKGROUND_COLOR_LIGHT: "#E7EAF2",
    NFT_DETAILS_ID_TEXT_COLOR_DARK: "#BDBDBD",
    NFT_DETAILS_ID_TEXT_COLOR_LIGHT: "#777E90",
    NFT_DETAILS_DESCRIPTION_TEXT_COLOR_DARK: "#BDBDBD",
    NFT_DETAILS_DESCRIPTION_TEXT_COLOR_LIGHT: "#505665",
    NFT_DETAILS_COLLECTION_TEXT_COLOR_DARK: "#F6F7F8",
    NFT_DETAILS_COLLECTION_TEXT_COLOR_LIGHT: "#505665",
    NFT_DETAILS_BURN_BUTTON_COLOR_DARK: "#1E1E21",
    NFT_DETAILS_BURN_BUTTON_COLOR_LIGHT: "#E7EAF2",
    NFT_DETAILS_BLOCK_ITEM_COLOR_DARK: "#23262F",
    NFT_DETAILS_BLOCK_ITEM_COLOR_LIGHT: "#FFFFFF",
    NFT_DETAILS_TAB_TEXT_COLOR_DARK: "#E0E0E0",
    NFT_DETAILS_TAB_TEXT_COLOR_LIGHT: "#505665",
    MINT_BLOCK_ITEM_COLOR_DARK: "#1E1E21",
    NOTIFICATION_COLOR_DARK: "#FFFFFF",
    MINT_BLOCK_ITEM_COLOR_LIGHT: "#E7EAF2",
    NOTIFICATION_COLOR_LIGHT: "#505665",
    NOTIFICATION_HOVER_COLOR_DARK: "#FFFFFF",
    NOTIFICATION_HOVER_BACKGROUND_COLOR_DARK: "#333642",
    NOTIFICATION_HOVER_COLOR_LIGHT: "#0044E3",
    NOTIFICATION_HOVER_BACKGROUND_COLOR_LIGHT: "#E7EAF2",
    NOTIFICATION_UNREAD_BACKGROUND_COLOR_DARK: "#293b58",
    NOTIFICATION_UNREAD_BACKGROUND_COLOR_LIGHT: "#E1F2FF",
    NOTIFICATION_ACTION_COLOR_DARK: "#DEE1EB",
    NOTIFICATION_ACTION_COLOR_LIGHT: "#505665",
    UPLOAD_BUTTON_COLOR_DARK: "#333642",
    UPLOAD_BUTTON_COLOR_LIGHT: "#E7EAF2",
    LANDING_BADGE_COLOR_DARK: "#FFFFFF",
    LANDING_BADGE_COLOR_LIGHT: "#23262F",
    LANDING_BADGE_TEXT_COLOR_DARK: "#23262F",
    LANDING_BADGE_TEXT_COLOR_LIGHT: "#FFFFFF",
    LANDING_BADGE_BORDER_COLOR_DARK: "#3C404D",
    LANDING_BADGE_BORDER_COLOR_LIGHT: "#FFFFFF",
    LANDING_TOP_COLLECTOR_COLOR_DARK: "#18181C",
    LANDING_TOP_COLLECTOR_COLOR_LIGHT: "rgba(231, 234, 242, 0.6)",
    LANDING_SORT_COLOR_DARK: "#1E1E21",
    LANDING_SORT_COLOR_LIGHT: "#E7EAF2",
    LANDING_INPUT_EMAIL_BORDER_COLOR_DARK: "#333642",
    LANDING_INPUT_EMAIL_BORDER_COLOR_LIGHT: "#BDC3D3",
    NFT_STAKING_POOL_BACKGROUND_COLOR_DARK: "linear-gradient(166.33deg, #121D23 0.48%, #191A22 98.72%)",
    NFT_STAKING_POOL_BACKGROUND_COLOR_LIGHT: "#FFFFFF",
    BLUE_1: "#E1F2FF",
    BLUE_2: "#0044E3",
    GRAY_1: "#505665",
    GRAY_2: "#3C404D",
    GRAY_3: "#DEE1EB",
    GRAY_4: "#828282",
    GRAY_5: "#E0E0E0",
    GRAY_6: "#23262F",
    GRAY_7: "#E7EAF2",
    GRAY_8: "#E5E5E5",
    GRAY_9: "#BDC3D3",
    GRAY_10: "#777E90",
    WHITE_1: "#FFFFFF",
    BLACK: "#000000",
    GRAY_11: "#2E2E35",
};

export const themes = {
    byIds: {
        [themeIds.LIGHT]: {
            id: themeIds.LIGHT,
            data: {
                common: {
                    textColor: colors.NERO,
                    linkColor: colors.ROYAL_BLUE,
                    activeTabColor: colors.ROYAL_BLUE,
                    activeTabTextColor: colors.PACIFIC_BLUE,
                    buttonTextColor: colors.ROYAL_BLUE,
                    buttonBackgroundColor: "none",
                    backgroundColor: colors.SOLITUDE,
                    containerBackgroundColor: colors.CONTAINER_BACKGROUND_COLOR_LIGHT,
                    blockItemBackgroundColor: colors.WHITE_SMOKE,
                    blockItemBackgroundColorDarken: colors.WHITE_SMOKE,
                    borderColor: colors.BORDER_COLOR_LIGHT,
                    menuBorderColor: colors.MENU_BORDER_COLOR_LIGHT,
                    headerTextColor: colors.KASHMIR_BLUE,
                    paginationTextColor: colors.NERO,
                    paginationActiveTextColor: colors.NEON_BLUE,
                    resultIconSuccessColor: colors.LIME_GREEN,
                    resultIconFailureColor: colors.RADICAL_RED,
                    resultIconPendingColor: colors.YELLOW_SEA,
                    placeholderTextColor: colors.LIGHT_GREY,
                    gradientActiveTextColor_1: colors.WHITE_SMOKE,
                    gradientActiveTextColor_2: colors.WHITE_SMOKE,
                    gradientActiveBackgroundColor_1: colors.NEON_BLUE,
                    gradientActiveBackgroundColor_2: colors.VENICE_BLUE,
                    gradientTextColor_1: colors.LIGHT_GRADIENT_1,
                    gradientTextColor_2: colors.LIGHT_GRADIENT_2,
                    usdCurrencyBorderColor: colors.USD_CURRENCY_BORDER_COLOR_LIGHT,
                    usdCurrencyTextColor: colors.USD_CURRENCY_TEXT_COLOR_LIGHT,
                    hoverBackgroundColor: colors.HOVER_COLOR_1,
                    hoverBackgroundColorGray: colors.GRAY_3,
                    underlineColor: colors.UNDER_LINE_COLOR_LIGHT,
                    sliderTextBackgroundColor: colors.SLIDER_TEXT_BACKGROUND_COLOR_LIGHT,
                    scrollBarBackgroundColor: colors.SCROLL_BAR_BACKGROUND_COLOR_LIGHT,
                    notificationIconColor: colors.NOTIFICATION_COLOR_LIGHT,
                    notificationIconHoverColor: colors.NOTIFICATION_HOVER_COLOR_LIGHT,
                    notificationUnreadColor: colors.NOTIFICATION_UNREAD_BACKGROUND_COLOR_LIGHT,
                    notificationIconBackgroundHoverColor: colors.NOTIFICATION_HOVER_BACKGROUND_COLOR_LIGHT,
                    notificationActionTextColor: colors.NOTIFICATION_ACTION_COLOR_LIGHT,
                    textColorGray1: colors.GRAY_1,
                    backgroundColorGray: colors.GRAY_7,
                    priceTextColor: colors.GRAY_10,
                    modal: {
                        modalCommonTextColor: colors.PACIFIC_BLUE,
                        modalCommonBorderColor: colors.PACIFIC_BLUE,
                        modalCommonBackgroundColor: colors.PACIFIC_BLUE,
                        buttonCancelBackgroundColor: "none",
                        buttonSubmitBackgroundColor: colors.DEEP_SKY_BLUE,
                        modalSliderColor: colors.PACIFIC_BLUE,
                    },
                    themedTable: {
                        headerBackgroundColor: colors.WHITE_SMOKE,
                        oddRowBackgroundColor: colors.WHITE,
                        evenRowBackgroundColor: colors.WHITE_SMOKE,
                    },
                    transactions: {
                        moreIconColor: colors.DARK_TANGERINE,
                        transactionTypeLabelColor: colors.NEON_BLUE,
                        transactionTypeBackgroundColor: "none",
                        transactionTypeTextColor: colors.NEON_BLUE,
                        amountTransactionLabelInColor: colors.LIME_GREEN,
                        amountTransactionBackgroundInColor: "none",
                        amountTransactionTextInColor: colors.LIME_GREEN,
                        amountTransactionLabelOutColor: colors.SUNSHADE,
                        amountTransactionBackgroundOutColor: "none",
                        amountTransactionTextOutColor: colors.SUNSHADE,
                    },
                    nftDetails: {
                        activeTabColor: colors.ACTIVE_TAB_COLOR_LIGHT,
                        tableHeaderColor: colors.TABLE_HEADER_COLOR_LIGHT,
                        tableBorderColor: colors.TABLE_BORDER_COLOR_LIGHT,
                        idTextColor: colors.NFT_DETAILS_ID_TEXT_COLOR_LIGHT,
                        descriptionTextColor: colors.NFT_DETAILS_DESCRIPTION_TEXT_COLOR_LIGHT,
                        collectionTextColor: colors.NFT_DETAILS_COLLECTION_TEXT_COLOR_LIGHT,
                        burnButtonBackgroundColor: colors.NFT_DETAILS_BURN_BUTTON_COLOR_LIGHT,
                        blockItemBackgroundColor: colors.NFT_DETAILS_BLOCK_ITEM_COLOR_LIGHT,
                        tabTextColor: colors.NFT_DETAILS_TAB_TEXT_COLOR_LIGHT,
                        quantityBackgroundColor: colors.BLUE_1,
                        quantityTextColor: colors.BLUE_2,
                        separateLineColor: colors.GRAY_3,
                    },
                    wallet: {
                        dropdownBackgroundColor: colors.WALLET_DROPDOWN_COLOR_LIGHT,
                        headerBackgroundColor: colors.WALLET_HEADER_COLOR_LIGHT,
                        borderColor: colors.MENU_BORDER_COLOR_LIGHT,
                        usdDenomTextColor: colors.WALLET_USD_DENOM_COLOR_LIGHT,
                        darkThemeTextColor: colors.WALLET_DARKTHEME_TEXT_COLOR_LIGHT,
                        borderColorButton: colors.UNDER_LINE_COLOR_LIGHT,
                        textColor: colors.GRAY_6,
                    },
                    profile: {
                        dropdownHoverBackgroundColor: colors.PROFILE_HOVER_COLER_LIGHT,
                        dropdownActiveItemColor: colors.PROFILE_ACTIVE_ITEM_LIGHT,
                        borderPrivateDisplayColor: colors.GRAY_6,
                        textColorGray1: colors.GRAY_1,
                        tabShadowLeftColor: "linear-gradient(90deg, #F7F8F9 0%, rgba(247, 248, 249, 0) 100%)",
                        tabShadowRightColor: "linear-gradient(90deg, rgba(247, 248, 249, 0) 0%, #F7F8F9 100%)",
                    },
                    mint: {
                        blockItemColor: colors.MINT_BLOCK_ITEM_COLOR_LIGHT,
                        uploadButtonColor: colors.UPLOAD_BUTTON_COLOR_LIGHT,
                    },
                    landing: {
                        badgeColor: colors.LANDING_BADGE_COLOR_LIGHT,
                        badgeTextColor: colors.LANDING_BADGE_TEXT_COLOR_LIGHT,
                        topCollectorColor: colors.LANDING_TOP_COLLECTOR_COLOR_LIGHT,
                        badgeBorderColor: colors.LANDING_BADGE_BORDER_COLOR_LIGHT,
                        sortColor: colors.LANDING_SORT_COLOR_LIGHT,
                        inputBorderColor: colors.LANDING_INPUT_EMAIL_BORDER_COLOR_LIGHT,
                    },
                    mediaKit: {
                        descriptionTextColor: colors.GRAY_1,
                        cardDescriptionTextColor: colors.GRAY_6,
                    },
                    nftStaking: {
                        informationBackgroundColor: colors.GRAY_7,
                        poolBackgroundColor: colors.NFT_STAKING_POOL_BACKGROUND_COLOR_LIGHT,
                        borderColor: colors.GRAY_3,
                        backgroundGrayColor: "rgba(231, 234, 242, 0.5)",
                    },
                    navBar: {
                        navBarItemColor: colors.GRAY_1,
                        borderColor: colors.GRAY_3,
                        backgroundColorItemNavbar: colors.GRAY_7,
                        backgroundNavBar: colors.WHITE,
                    },
                    marketPlace: {
                        backgroundColorGray: colors.GRAY_7,
                        textColorItemCategory: colors.GRAY_6,
                        textColorItemCategoryActive: colors.BLUE_2,
                        textColorButton: colors.GRAY_1,
                        backgroundIconFilter: colors.BLUE_1,
                        textColorToolbarDropdown: colors.GRAY_6,
                        backgroundToolbar: colors.SOLITUDE,
                    },
                    simmilarityCheckReport: {
                        textColor: colors.GRAY_6,
                        backgroundColorGray: colors.WHITE_SMOKE,
                        backgroundColorGray2: colors.GRAY_7,
                        backgroundColorGray3: colors.BORDER_COLOR_DARK,
                        textColorGray: colors.WALLET_DARKTHEME_TEXT_COLOR_LIGHT,
                        textColorGray1: colors.BORDER_COLOR_LIGHT,
                        backGroundColorTag: colors.PROFILE_ACTIVE_ITEM_LIGHT,
                        textColorTag: colors.PACIFIC_BLUE,
                        backgroundModalCheckReport: colors.GRAY_8,
                        backgroundDemoCheckReport: colors.WHITE_SMOKE,
                        backgroundCardDemoCheckReport: colors.WHITE_SMOKE,
                        backgroundTopSimilarNFTItem: colors.WHITE,
                        backgroundColorStep: colors.GRAY_7,
                    },
                    swap: {
                        backgroundColorGray: colors.GRAY_7,
                        backgroundColorGray2: colors.WHITE_SMOKE,
                        borderColor: colors.GRAY_9,
                        backgroundColorStep: colors.GRAY_9,
                        backgroundColorGray3: colors.GRAY_7,
                    },
                },
            },
        },
        [themeIds.DARK]: {
            id: themeIds.DARK,
            data: {
                common: {
                    textColor: colors.HAWKES_BLUE,
                    linkColor: colors.DODGER_BLUE,
                    activeTabColor: colors.DODGER_BLUE,
                    activeTabTextColor: colors.NERO,
                    buttonTextColor: colors.HAWKES_BLUE,
                    buttonBackgroundColor: colors.DODGER_BLUE,
                    backgroundColor: colors.BLUE_WHALE,
                    containerBackgroundColor: colors.CONTAINER_BACKGROUND_COLOR_DARK,
                    blockItemBackgroundColor: colors.REGAL_BLUE_3,
                    blockItemBackgroundColorDarken: colors.REGAL_BLUE_3,
                    borderColor: colors.BORDER_COLOR_DARK,
                    menuBorderColor: colors.MENU_BORDER_COLOR_DARK,
                    headerTextColor: colors.HAWKES_BLUE,
                    paginationTextColor: colors.BERMUDA_GREY,
                    paginationActiveTextColor: colors.HAWKES_BLUE,
                    resultIconSuccessColor: colors.DODGER_BLUE,
                    resultIconFailureColor: colors.RADICAL_RED,
                    resultIconPendingColor: colors.YELLOW_SEA,
                    placeholderTextColor: colors.LIGHT_GREY_2,
                    gradientActiveTextColor_1: colors.NEON_BLUE,
                    gradientActiveTextColor_2: colors.VENICE_BLUE,
                    gradientActiveBackgroundColor_1: colors.REGAL_BLUE_2,
                    gradientActiveBackgroundColor_2: colors.REGAL_BLUE_2,
                    gradientTextColor_1: colors.DARK_GRADIENT_1,
                    gradientTextColor_2: colors.DARK_GRADIENT_2,
                    usdCurrencyBorderColor: colors.USD_CURRENCY_BORDER_COLOR_DARK,
                    usdCurrencyTextColor: colors.USD_CURRENCY_TEXT_COLOR_DARK,
                    hoverBackgroundColor: colors.HOVER_COLOR_2,
                    hoverBackgroundColorGray: colors.PROFILE_HOVER_COLER_DARK,
                    underlineColor: colors.UNDER_LINE_COLOR_DARK,
                    sliderTextBackgroundColor: colors.SLIDER_TEXT_BACKGROUND_COLOR_DARK,
                    scrollBarBackgroundColor: colors.SCROLL_BAR_BACKGROUND_COLOR_DARK,
                    notificationIconColor: colors.NOTIFICATION_COLOR_DARK,
                    notificationIconHoverColor: colors.NOTIFICATION_HOVER_COLOR_DARK,
                    notificationUnreadColor: colors.NOTIFICATION_UNREAD_BACKGROUND_COLOR_DARK,
                    notificationIconBackgroundHoverColor: colors.NOTIFICATION_HOVER_BACKGROUND_COLOR_DARK,
                    notificationActionTextColor: colors.NOTIFICATION_ACTION_COLOR_DARK,
                    textColorGray1: colors.WHITE_SMOKE,
                    backgroundColorGray: colors.GRAY_2,
                    priceTextColor: colors.GRAY_9,
                    modal: {
                        modalCommonTextColor: colors.HAWKES_BLUE,
                        modalCommonBorderColor: colors.VENICE_BLUE,
                        modalCommonBackgroundColor: colors.REGAL_BLUE_1,
                        buttonCancelBackgroundColor: colors.REGAL_BLUE_2,
                        buttonSubmitBackgroundColor: colors.DODGER_BLUE,
                        modalSliderColor: colors.DODGER_BLUE,
                    },
                    themedTable: {
                        headerBackgroundColor: colors.REGAL_BLUE_1,
                        oddRowBackgroundColor: colors.REGAL_BLUE_2,
                        evenRowBackgroundColor: colors.REGAL_BLUE_1,
                    },
                    transactions: {
                        moreIconColor: colors.YELLOW_SEA,
                        transactionTypeLabelColor: colors.NEON_BLUE,
                        transactionTypeBackgroundColor: colors.NEON_BLUE,
                        transactionTypeTextColor: colors.HAWKES_BLUE,
                        amountTransactionLabelInColor: colors.DODGER_BLUE,
                        amountTransactionBackgroundInColor: colors.DODGER_BLUE,
                        amountTransactionTextInColor: colors.HAWKES_BLUE,
                        amountTransactionLabelOutColor: colors.YELLOW_SEA,
                        amountTransactionBackgroundOutColor: colors.YELLOW_SEA,
                        amountTransactionTextOutColor: colors.HAWKES_BLUE,
                    },
                    nftDetails: {
                        activeTabColor: colors.ACTIVE_TAB_COLOR_DARK,
                        tableHeaderColor: colors.TABLE_HEADER_COLOR_DARK,
                        tableBorderColor: colors.TABLE_BORDER_COLOR_DARK,
                        idTextColor: colors.NFT_DETAILS_ID_TEXT_COLOR_DARK,
                        descriptionTextColor: colors.NFT_DETAILS_DESCRIPTION_TEXT_COLOR_DARK,
                        collectionTextColor: colors.NFT_DETAILS_COLLECTION_TEXT_COLOR_DARK,
                        burnButtonBackgroundColor: colors.NFT_DETAILS_BURN_BUTTON_COLOR_DARK,
                        blockItemBackgroundColor: colors.NFT_DETAILS_BLOCK_ITEM_COLOR_DARK,
                        tabTextColor: colors.NFT_DETAILS_TAB_TEXT_COLOR_DARK,
                        quantityBackgroundColor: colors.GRAY_1,
                        quantityTextColor: colors.WHITE_1,
                        separateLineColor: colors.GRAY_2,
                    },
                    wallet: {
                        dropdownBackgroundColor: colors.WALLET_DROPDOWN_COLOR_DARK,
                        headerBackgroundColor: colors.WALLET_HEADER_COLOR_DARK,
                        borderColor: colors.MENU_BORDER_COLOR_DARK,
                        usdDenomTextColor: colors.WALLET_USD_DENOM_COLOR_DARK,
                        darkThemeTextColor: colors.WALLET_DARKTHEME_TEXT_COLOR_DARK,
                        borderColorButton: colors.GRAY_1,
                        textColor: colors.WHITE_SMOKE,
                    },
                    profile: {
                        dropdownHoverBackgroundColor: colors.PROFILE_HOVER_COLER_DARK,
                        dropdownActiveItemColor: colors.PROFILE_ACTIVE_ITEM_DARK,
                        borderPrivateDisplayColor: colors.GRAY_6,
                        textColorGray1: colors.WHITE,
                        tabShadowLeftColor: "linear-gradient(90deg, #141416 0%, rgba(20, 20, 22, 0) 100%)",
                        tabShadowRightColor: "linear-gradient(90deg, rgba(20, 20, 22, 0) 0%, #141416 100%)",
                    },
                    mint: {
                        blockItemColor: colors.MINT_BLOCK_ITEM_COLOR_DARK,
                        uploadButtonColor: colors.UPLOAD_BUTTON_COLOR_DARK,
                    },
                    landing: {
                        badgeColor: colors.LANDING_BADGE_COLOR_DARK,
                        badgeTextColor: colors.LANDING_BADGE_TEXT_COLOR_DARK,
                        topCollectorColor: colors.LANDING_TOP_COLLECTOR_COLOR_DARK,
                        badgeBorderColor: colors.LANDING_BADGE_BORDER_COLOR_DARK,
                        sortColor: colors.LANDING_SORT_COLOR_DARK,
                        inputBorderColor: colors.LANDING_INPUT_EMAIL_BORDER_COLOR_DARK,
                    },
                    mediaKit: {
                        descriptionTextColor: colors.GRAY_4,
                        cardDescriptionTextColor: colors.GRAY_5,
                    },
                    nftStaking: {
                        informationBackgroundColor: colors.GRAY_6,
                        poolBackgroundColor: colors.NFT_STAKING_POOL_BACKGROUND_COLOR_DARK,
                        borderColor: colors.BORDER_COLOR_DARK,
                        backgroundGrayColor: colors.GRAY_6,
                    },
                    navBar: {
                        navBarItemColor: colors.UNDER_LINE_COLOR_LIGHT,
                        borderColor: colors.GRAY_2,
                        backgroundColorItemNavbar: colors.REGAL_BLUE_3,
                        backgroundNavBar: colors.BLACK,
                    },
                    marketPlace: {
                        backgroundColorGray: colors.REGAL_BLUE_3,
                        textColorItemCategory: colors.GRAY_5,
                        textColorItemCategoryActive: colors.TABLE_HEADER_COLOR_DARK,
                        textColorButton: colors.GRAY_3,
                        backgroundIconFilter: colors.BORDER_COLOR_DARK,
                        textColorToolbarDropdown: colors.GRAY_5,
                        backgroundToolbar: colors.BLUE_WHALE,
                    },
                    simmilarityCheckReport: {
                        textColor: colors.WHITE_1,
                        backgroundColorGray: colors.REGAL_BLUE_3,
                        backgroundColorGray2: colors.REGAL_BLUE_3,
                        backgroundColorGray3: colors.REGAL_BLUE_3,
                        textColorGray: colors.BORDER_COLOR_LIGHT,
                        textColorGray1: colors.BORDER_COLOR_LIGHT,
                        backGroundColorTag: colors.BORDER_COLOR_DARK,
                        textColorTag: colors.HAWKES_BLUE,
                        backgroundModalCheckReport: colors.BLUE_WHALE,
                        backgroundDemoCheckReport: colors.REGAL_BLUE_3,
                        backgroundCardDemoCheckReport: colors.GRAY_11,
                        backgroundTopSimilarNFTItem: colors.GRAY_6,
                        backgroundColorStep: colors.GRAY_6,
                    },
                    swap: {
                        backgroundColorGray: colors.REGAL_BLUE_3,
                        backgroundColorGray2: colors.BORDER_COLOR_DARK,
                        borderColor: colors.GRAY_2,
                        backgroundColorStep: colors.GRAY_6,
                        backgroundColorGray3: colors.BORDER_COLOR_DARK,
                    },
                },
            },
        },
    },
    allIds: Object.values(themeIds),
};
