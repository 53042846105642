import React from "react";

const SellIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg className={className} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.00003 14L7.00003 4L10.5 7.50001L12 6.00002L6.00001 0L1.71661e-05 5.99999L1.50001 7.49998L5.00001 4L5.00001 14H7.00003Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default SellIcon;
