import React from "react";
import cn from "classnames/bind";
import _ from "lodash";
import styles from "./MainLayout.module.scss";
const cx = cn.bind(styles);

const MainLayout: React.FC<{
    className?: string;
    backgroundImage?: string;
    isErc20?: boolean;
    isHaveBackground?: boolean;
}> = ({ className, children, backgroundImage, isErc20, isHaveBackground }) => {
    const style: React.CSSProperties = {};
    if (!_.isNil(backgroundImage)) {
        style.background = `url('${backgroundImage}') no-repeat top`;
        style.backgroundSize = "100% 100%"
    } else if (_.isNil(isHaveBackground)) style.background = "linear-gradient(#2F2461, #0E1F4F)";

    return (
        <div className={cx("main-layout")}>
            <div className={cx("main", !_.isNil(className) && className)} style={style}>
                {children}
            </div>
        </div>
    );
};

export default MainLayout;
