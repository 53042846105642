
import React, { ReactNode } from "react";
import cn from "classnames/bind";
import MainLayout from "src/components/layouts/MainLayout/MainLayout";
import styles from "./ComingSoon.module.scss";

const cx = cn.bind(styles);

const ComingSoon: React.FC<{ children?: ReactNode }> = ({ children }) => {
    return (
        <MainLayout>
            <div className={cx("background")}>
                <div className={`container ${cx("wrapper")}`}>
                    <div className={cx("text")}>{children}</div>
                </div>
            </div>
        </MainLayout>
    );
};

export default ComingSoon;
