export default function (number, n, x, s, c) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
        num = number.toFixed(Math.max(0, ~~n));
    return (c ? num.replace(".", c) : num).replace(
        new RegExp(re, "g"),
        "$&" + (s || ",")
    );
}

export const largeNumber = (value, withSign = true) => {
    let sign = value < 0 ? "-" : "";
    if (withSign)
        sign = value > 0 ? "+" : value < 0 ? "-" : "";
    let absValue = Math.abs(value);
    if (absValue < 10 ** 6)
        return sign + (Math.round(absValue * 100) / 100).toLocaleString();
    if (absValue < 10 ** 9)
        return sign + (Math.round(absValue / 10 ** 4) / 100).toLocaleString() + "M";
    return sign + (Math.round(absValue / 10 ** 7) / 100).toLocaleString() + "B";
};