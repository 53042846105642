// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import WrapLink from "../WrapLink/WrapLink";
import { Link, useHistory } from "react-router-dom";
import AirDrop from "./AirDrop";
import cn from "classnames/bind";
import { NavDropdown } from "react-bootstrap";
import { DOMAIN } from "src/consts/domain";
import styles from "./NavLinks.module.scss";
import { useCheckMobileScreen } from "src/utils/hooks";
import { useGet } from "restful-react";
import ButtonBuy from "src/components/pages/OldLanding/ButtonBuy";
import TabDownArrowIcon from "src/assets/icons/TabDownArrowIcon";
import { CaretRightOutlined } from "@ant-design/icons";

// icons
import IconExplore from "src/assets/icons/icon-explore.svg";
import IconCollection from "src/assets/icons/icon-collections.svg";
import IconGallery from "src/assets/icons/icon-gallery.svg";
import KawaiiLogo from "src/assets/icons/kawaii-logo.svg";

const cx = cn.bind(styles);

type LinkProps = {
    path: string;
    title: string;
    description?: string;
    items?: LinkProps[];
    handleNextStepMenu: Function;
};

type NavLinksProps = {
    items?: LinkProps[];
    current: string;
    onItemClick?: Function;
    handleNextStepMenu: Function;
};

export const Links: LinkProps[] = [
    {
        path: "/feed",
        title: "Feed",
        description: "Feed",
    },
    {
        path: "/ai-models",
        title: "Mint AI NFT",
        description: "Let's discover many awesome AI models",
    },
    {
        path: "#",
        title: "AI Oracle",
        description: "AI Oracle",
        classMore: "menu-drop-left",
        items: [
            {
                title: "AI-based NFT Authenticity Verification",
                path: "/nft-content-authenticity",
            },
            {
                title: "Royalty Protocol",
                path: "https://blog.airight.io/adapting-oraichain-royalty-protocol-for-nfts-on-airight-8c1e74a36be3",
                isBlank: true,
            }
        ]
    },
    {
        path: "/marketplace",
        title: "Marketplace",
        description: "Start collecting or selling artworks",
        items: [
            {
                title: "All NFTs",
                path: "/marketplace",
                icon: IconExplore,
            },
            {
                title: "Collections",
                path: "/collections",
                icon: IconCollection,
            },
            {
                title: "Gallery",
                path: "/nft-gallery",
                icon: IconGallery,
            },
            {
                title: "Kawaii Islands",
                path: "https://kawaii-islands.airight.io/",
                icon: KawaiiLogo,
                isBlank: true,
            },
        ],
    },
    {
        path: "/token-farming",
        title: "Farming",
        description: "NFT FARMING",
        items: [
            {
                title: "NFT Staking",
                path: "/nft-staking",
                icon: IconExplore,
            },
            {
                title: "Tokens Farming",
                path: "/token-farming",
                icon: IconExplore,
            },
        ],
    },
    // {
    //     path: "/original-check",
    //     title: "AI Oracles",
    //     description: "Originality Check",
    // },
    {
        path: "http://docs.airight.io/",
        title: "Docs",
        description: "",
    },
    {
        path: "https://patentscope.wipo.int/search/en/detail.jsf?docId=WO2022200834&_cid=P12-LBD67M-45114-1",
        title: "Patent",
        description: "",
    },
    // {
    //     path: "/buy-airi",
    //     title: "Buy AIRI",
    //     description: "",
    // },
];

const NavLinkChildren: React.FC<LinkProps> = ({ items, title, path, onItemClick, current, show, setShow, handleNextStepMenu, classMore }) => {
    const isMobileScreenLTE = useCheckMobileScreen(1200);
    const history = useHistory();

    return isMobileScreenLTE ? (
        <div onClick={() => handleNextStepMenu(path)}>{title}</div>
    ) : (
        <div className={cx("nav-dropdown-desktop") + (classMore ? (' ' + cx(classMore)) : '')} style={{ marginBottom: "-20px", paddingBottom: "20px" }}>
            <div
                onClick={() => {
                    history.push(path);
                }}
                style={{ cursor: "pointer" }}
                onMouseEnter={() => {
                    setShow(true);
                }}
                className={cx("nav-link", { active: current === path })}
            >
                {title}
            </div>
            <div className={cx("dropdown-menu")}>
                {items?.map(({ path, title, description, isBlank }) => {
                    return (
                        <div
                            className={cx("nav-item")}
                            onClick={() => {
                                isBlank ? window.open(path) : history.push(path);
                                // hideDropdown?.();
                                onItemClick?.();
                            }}
                        >
                            {title}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const NavLinks: React.FC<NavLinksProps> = ({ items = Links, current, onItemClick, handleNextStepMenu }) => {
    const [show, setShow] = useState(false);
    const dropdownRef = useRef(null);
    const isMobileScreenLTE = useCheckMobileScreen(1200);

    const showDropdown = (e: any) => {
        setShow(true);
    };

    const hideDropdown = (e: any) => {
        setShow(false);
    };

    const handleClickOutside = (event: any) => {
        if (dropdownRef && !dropdownRef?.current?.contains?.(event?.target)) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, []);

    return (
        <>
            {items.map((child) => {
                const { path, title } = child;

                if (child.title === "Buy AIRI") {
                    return <ButtonBuy onItemClick={onItemClick} path={path} title={title} current={current} />;
                }

                if (child.title === "Airdrop") {
                    return <AirDrop />;
                }

                if (child?.items) {
                    return (
                        <div
                            className={cx("text-wrapper", { mobile: isMobileScreenLTE })}
                            ref={dropdownRef}
                            onMouseEnter={showDropdown}
                            onMouseLeave={hideDropdown}
                            onClick={() => {
                                if (isMobileScreenLTE) {
                                    return handleNextStepMenu(path);
                                }

                                return;
                            }}
                        >
                            <NavLinkChildren
                                show={show}
                                setShow={setShow}
                                current={current}
                                onItemClick={onItemClick}
                                key={child.path}
                                handleNextStepMenu={handleNextStepMenu}
                                {...child}
                            />
                            {isMobileScreenLTE ? <CaretRightOutlined /> : <TabDownArrowIcon className={cx("more-icon")} />}
                        </div>
                    );
                }
                return (
                    <WrapLink onClick={onItemClick} key={path} className={cx("nav-link", { active: current === path })} to={path}>
                        {title}
                    </WrapLink>
                );
            })}
        </>
    );
};

export default NavLinks;
