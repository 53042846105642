import React from "react";

const CenterEllipsis: React.FC<{ text: string; size: number; className?: string }> = ({ text, size, className }) => {
    if (!text) {
        text = '';
    }
    return <p className={className} style={{ marginBottom: 0 }}>{`${text.slice(0, size)}...${text.slice(-size)}`}</p>;
};

export default CenterEllipsis;
