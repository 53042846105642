import React from "react";

const BuyIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg className={className} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.99997 0L4.99997 10L1.49999 6.49999L0 7.99998L5.99999 14L12 8.00001L10.5 6.50002L6.99999 10L6.99999 0H4.99997Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default BuyIcon;
