// @ts-nocheck

import React, { useState, useEffect } from "react";
import cn from "classnames/bind";
import styles from "./components.module.scss";
import { read, write, sign } from "src/utils/web3Service";
import GALLERY_ABI from "src/utils/abi/gallery.json";
import RELAY_ABI from "src/utils/abi/relay.json";
import { RELAY_ADDRESS } from "src/consts/address";
import LoadingModal from "src/components/common/LoadingModal/LoadingModal";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core";
import { CHAIN_ID } from "src/consts/web3";
import { useWeb3React } from "@web3-react/core";
import createNetworkOrSwitch from "src/utils/createNetworkOrSwitch";
import Pagination from "src/components/common/Pagination/Pagination";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const cx = cn.bind(styles);
const numberPerPage = 5;

const NftCollection: React.FC<{
    account;
    galleryId;
    getListAuction;
    getBalance;
    onHide;
    auctionIdx;
    getListNFTs;
    symbol;
}> = ({
    account,
    galleryId,
    getListAuction,
    getBalance,
    onHide,
    auctionIdx,
    getListNFTs,
    symbol,
}) => {
        const context = useWeb3React();
        const { library, chainId } = context;
        const [listBid, setListBid] = useState([]);
        const [listAllowance, setListAllowance] = useState([]);
        const [listBalance, setListBalance] = useState([]);
        const [stepLoading, setStepLoading] = useState(0);
        const [showModalLoading, setShowModalLoading] = useState(false);
        const [loading, setLoading] = useState(false);
        const [hash, setHash] = useState();
        const [currentPage, setCurrentPage] = useState(1);
        const [totalPage, setTotalPage] = useState(1);
        const history = useHistory();

        useEffect(() => {
            getListBid();
        }, []);

        const finish = async (bidId) => {
            try {
                setLoading(true);
                setStepLoading(0);
                setShowModalLoading(true);
                const callback = (hash) => {
                    setHash(hash);
                    setStepLoading(1);
                };
                if (chainId !== CHAIN_ID) {
                    if (chainId !== CHAIN_ID) {
                        const error = await createNetworkOrSwitch();
                        if (error) {
                            throw new Error(
                                "Please change network to Binance smart chain"
                            );
                        }
                    }
                }
                const deadline = Math.floor(Date.now() / 1000) + 30 * 60;
                const nonce = await read("nonces", galleryId, GALLERY_ABI, [
                    account,
                ]);
                const contractName = await read("NAME", galleryId, GALLERY_ABI, []);
                console.log("contract name ", contractName);

                const EIP712Domain = [
                    { name: "name", type: "string" },
                    { name: "version", type: "string" },
                    { name: "chainId", type: "uint256" },
                    { name: "verifyingContract", type: "address" },
                ];

                const domain = {
                    name: contractName,
                    version: "1",
                    chainId: CHAIN_ID,
                    verifyingContract: galleryId,
                };

                const Data = [
                    { name: "bidId", type: "uint256" },
                    { name: "auctionId", type: "uint256" },
                    { name: "deadline", type: "uint256" },
                    { name: "nonce", type: "uint256" },
                ];
                const message = {
                    bidId,
                    auctionId: auctionIdx,
                    deadline,
                    nonce,
                };
                const data = JSON.stringify({
                    types: {
                        EIP712Domain,
                        Data,
                    },
                    domain,
                    primaryType: "Data",
                    message,
                });
                const signature = await sign(account, data, library.provider);
                const { r, s, v } = signature;
                await write(
                    "finishAuctionPermit",
                    library.provider,
                    RELAY_ADDRESS,
                    RELAY_ABI,
                    account,
                    [galleryId, bidId, auctionIdx, deadline, nonce, v, r, s],
                    callback
                );
                getListAuction();
                getBalance();
                getListNFTs();
                setStepLoading(2);
                history.push(`/nft-gallery`);
            } catch (error) {
                console.log(error);
                setStepLoading(3);
                toast.error(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        const getListBid = async () => {
            const bidLength = await read("getBidLength", galleryId, GALLERY_ABI, [
                auctionIdx,
            ]);
console.log("lengt bid", bidLength);
            const listBid = await Promise.all(
                new Array(Number(bidLength))
                    .fill()
                    .map((i, idx) =>
                        read("bidData", galleryId, GALLERY_ABI, [auctionIdx, idx])
                    )
            );
            const listBalance = await Promise.all(
                listBid.map((i) =>
                    read("balanceOf", galleryId, GALLERY_ABI, [i.user])
                )
            );
            const listAllowance = await Promise.all(
                listBid.map((i) =>
                    read("allowance", galleryId, GALLERY_ABI, [i.user, galleryId])
                )
            );
            setListBalance(listBalance.map((i) => i / 10 ** 18));
            setListAllowance(listAllowance.map((i) => i / 10 ** 18));
            setListBid(listBid);
            setTotalPage(Math.ceil(listBid.length / numberPerPage) || 1);
        };

        return (
            <div className={cx("card-content")}>
                <div className={cx("content")} style={{ alignItems: 'center' }}>
                    <div className={cx("finish")}>
                        <div className={cx("title")}>Choose bid to finish</div>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow className={cx("table-header")}>
                                        <TableCell className={cx("cell")}>User</TableCell>
                                        <TableCell className={cx("cell")}>Price</TableCell>
                                        <TableCell className={cx("cell")}>
                                            Enough balance
                                        </TableCell>
                                        <TableCell className={cx("cell")}>
                                            Enough allowance
                                        </TableCell>
                                        <TableCell className={cx("cell")}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listBid
                                        .slice(
                                            (currentPage - 1) * numberPerPage,
                                            currentPage * numberPerPage
                                        )
                                        .map((bid, idx) => (
                                            <TableRow key={idx} className={cx("table-row")}>
                                                <TableCell className={cx("cell")}>
                                                    {bid[0].slice(0, 3) +
                                                        "..." +
                                                        bid[0].slice(bid[0].length - 3)}
                                                </TableCell>
                                                <TableCell className={cx("cell")}>
                                                    {bid[1] / 10 ** 18} {symbol}
                                                </TableCell>
                                                <TableCell className={cx("cell")}>
                                                    {bid[1] / 10 ** 18 <= listBalance[idx]
                                                        ? "Yes"
                                                        : "No"}
                                                </TableCell>
                                                <TableCell className={cx("cell")}>
                                                    {bid[1] / 10 ** 18 <= listAllowance[idx]
                                                        ? "Yes"
                                                        : "No"}
                                                </TableCell>
                                                <TableCell className={cx("cell")}>
                                                    {bid[1] / 10 ** 18 <= listBalance[idx] &&
                                                        bid[1] / 10 ** 18 <=
                                                        listAllowance[idx] && (
                                                            <button
                                                                onClick={() => {
                                                                    finish(idx);
                                                                }}
                                                                className={cx("button")}
                                                                disabled={loading}
                                                            >
                                                                Choose
                                                            </button>
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <div className={cx("pagination")}>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPage}
                        onChange={(newPage: number) => {
                            setCurrentPage(newPage);
                        }}
                    />
                </div>

                {showModalLoading && (
                    <LoadingModal
                        show={true}
                        loading={loading}
                        title="Finishing NFT"
                        stepLoading={stepLoading}
                        onHide={() => {
                            setShowModalLoading(false);
                            setHash(null);
                            setStepLoading(0);
                        }}
                        hash={hash}
                        hideParent={onHide}
                    />
                )}
            </div>
        );
    };

export default NftCollection;
