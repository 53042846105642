// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import cn from "classnames/bind";
import styles from "./Landing.module.scss";
import { useGet } from "restful-react";
import WrapLink from "src/components/common/WrapLink/WrapLink";
import logo from "src/assets/images/LogoAIRI.svg";

const cx = cn.bind(styles);

const ButtonBuy: React.FC<{ onItemClick?: any; path?: any; current?: any; title?: any }> = ({ onItemClick, path, current, title }) => {
    const { data: infoData } = useGet({ path: "https://api-liquidity.airight.io/v1/airi-info" });
    return (
        <WrapLink
            onClick={onItemClick}
            key={infoData?.data?.url}
            className={cx("nav-link", { active: current === infoData?.data?.url })}
            // to={infoData?.data?.url || ""}
            to={path}
        >
            <img height={16} style={{ marginRight: 4 }} src={logo} alt='AI Right' />
            {title}
        </WrapLink>
    );
};

export default ButtonBuy;
