import { createSlice } from "@reduxjs/toolkit";
import { IWallet, IState } from 'src/types/redux';

export const priceSlice = createSlice({
    name: "price",
    initialState: {
        usd: 0
    },
    reducers: {
        setPrice: (state: any, action) => {
            return Object.assign(state, action.payload);
        },
    },
});

export const { setPrice } = priceSlice.actions;

export const selectPrice = (state: IState): IWallet => {
    return state.price;
};

export default priceSlice.reducer;
