import React, { useEffect, useState } from 'react';
import { formatCurrency, defaultOptions } from 'src/utils/currency';
//hooks
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';
import { selectPrice } from 'src/store/slices/priceSlice';

interface PriceProps {
    orai: any;
    isMicro?: any;
}
interface ValueInterface {
    contextData: any;
    contextAction: any;
}

const defaultValue: ValueInterface = {
    contextData: null,
    contextAction: null
};

const NftContext = React.createContext(defaultValue);
export default NftContext;

export const NftProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    //origin state
    const [refreshApproval, setRefreshApproval] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [visibleTopUp, setVisibleTopUp] = useState(false);
    const [visibleSelectTopUp, setVisibleSelectTopUp] = useState(false);
    const [infoCheckout, setInfoCheckout] = useState(null);
    const [totalPrice, setTotalPrice] = useState(null);
    const [visibleConfirm, setVisibleConfirm] = useState(false);
    const [visibleBuyOrai, setVisibleBuyOrai] = useState(false);
    const [visibleSuccess, setVisibleSuccess] = useState(false);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [openCheckoutDialog, setOpenCheckoutDialog] = useState(false);
    const [openCheckoutDialogDetail, setOpenCheckoutDialogDetail] = useState(false);
    const [typeCheckout, setTypeCheckout] = useState('');
    const [typeTopUp, setTypeTopUp] = useState('');
    const [txhash, setTxhash] = useState('');
    const [resetContext, setResetContext] = useState(false);

    useEffect(() => {
        setRefreshApproval(false);
        setIsSuccess(false);
        setVisibleTopUp(false);
        setVisibleSelectTopUp(false);
        setInfoCheckout(null);
        setTotalPrice(null);
        setVisibleConfirm(false);
        setVisibleBuyOrai(false);
        setVisibleSuccess(false);
        setVisibleLoading(false);
        setOpenCheckoutDialog(false);
        setOpenCheckoutDialogDetail(false);
        setTypeCheckout('');
        setTypeTopUp('');
        setTxhash('');
    }, [resetContext]);

    //wallet value
    const context = useWeb3React();
    const { account } = context;
    const { wallet, publicAddress, balance, airiBalance } = useSelector((state: any) => ({
        wallet: state?.wallet,
        publicAddress: state?.wallet?.publicAddress,
        balance: state?.wallet?.balance,
        airiBalance: state?.wallet?.airiBalance
    }));

    //Format Currency
    const price: any = useSelector(selectPrice);

    //convert orai to dolar
    const OraiToDolar = ({ orai, isMicro = true }: PriceProps) => {
        if (!orai) return 0;
        if (isMicro) {
            return formatCurrency((orai * price?.['usd']) / Math.pow(10, defaultOptions.significantDigits), 2);
        }
        return formatCurrency(orai * price?.['usd'], 2);
    };

    //Init context value
    const contextData = {
        wallet: {
            info: publicAddress && publicAddress !== '0x' ? wallet : null,
            erc20Wallet: account && account !== '0x' ? account : null,
            balance,
            publicAddress,
            airiBalance
        },
        visibleTopUp,
        infoCheckout,
        visibleConfirm,
        totalPrice,
        visibleLoading,
        visibleBuyOrai,
        visibleSuccess,
        txhash,
        isSuccess,
        typeCheckout,
        openCheckoutDialog,
        openCheckoutDialogDetail,
        typeTopUp,
        visibleSelectTopUp,
        refreshApproval,
        resetContext
    };
    const contextAction = {
        setVisibleTopUp,
        setInfoCheckout,
        setVisibleConfirm,
        setTotalPrice,
        setVisibleBuyOrai,
        OraiToDolar,
        setVisibleLoading,
        setVisibleSuccess,
        setTxhash,
        setIsSuccess,
        setTypeCheckout,
        setOpenCheckoutDialog,
        setOpenCheckoutDialogDetail,
        setTypeTopUp,
        setVisibleSelectTopUp,
        setRefreshApproval,
        setResetContext
    };

    return (
        <NftContext.Provider
            value={{
                contextData,
                contextAction
            }}
        >
            {children}
        </NftContext.Provider>
    );
};
