export const MASTER_CHEF_ADDRESS = "0xd3393051cD4f95666fB2065D68f73E05fb993d4c";
export const CREATE_GALLERY_ADDRESS = "0x8136bcBe782F4B5E64bA9488B857267A9Ffe7391";
// export const CREATE_GALLERY_ADDRESS =
//     "0xC8abc0be5a3CA6caE621Ff4F979c46449adb433C";
// export const AIRI_ADDRESS = "0x12351a29917288C8e4e5d588D1C98A5370d094D3";
export const AIRI_ADDRESS = "0xd0ac7511edae21d963450e621ed0a0599f1a0a7c";
export const REGISTER_ADDRESS = "0xbda016E89F7FeB6DA211017d8092E126F998B73e";
// export const REGISTER_ADDRESS = "0x4E89119f9E9e7C501A9CF89b8c91A847129df2E3";
export const FARM_FACTORY_ADDRESS = "0xc8ac6D5e5EbD31a5889314274118aD787C7EB2Ca";
export const AIR_DROP_ADDRESS = "0x25D775D4aC76fD58A14cF1724ddCAc9d384D187e";
export const REGISTER_NFT_ADDRESS = "0x4E89119f9E9e7C501A9CF89b8c91A847129df2E3";
export const SWAP_NFT_ADDRESS = "0xeA160ab84893E279C526bdc37E303a0A4539d5f4";
export const RELAY_ADDRESS = "0x77F5872FB2deD020dCb22389B26fd321739Ec197";
// export const RELAY_ADDRESS = "0x7aF1f5Fe895c92bE3bC2c47D276952Eab9179c36";
export const FARMING_RELAY_ADDRESS = "0x77F5872FB2deD020dCb22389B26fd321739Ec197";
export const PANCAKE_FACTORY_ADDRESS = "0xca143ce32fe78f1f7019d7d551a6402fc5350c73";
export const PANCAKESWAP_ROUTER = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
export const WBNB_ADDRESS = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const ORAI_BSC_SWAP = "0x77F5872FB2deD020dCb22389B26fd321739Ec197";
export const SAMORAI_BNB_POOL = "0xe450ED7bc4FbBbBb4e7612154b1F182A12751164";
export const LOCK_LIQUIDITY = "0x8D69FBCc552bc2500aD97025e30504Ff5C31A425";
export const AIRI_ADDRESS1 = "0x7e2a35c746f2f7c240b664f1da4dd100141ae71f";
export const ORAI_ADDRESS = "0xA325Ad6D9c92B55A3Fc5aD7e412B1518F96441C0";
export const SAMORAI_ADDRESS = "0xE65B9e5fd7029169a81F13910F49c174d851f393";
export const AIBLACK_ADDRESS = "0x1ACE3b077Fd483fEC8F12E5a3488e52A90Db98aB";
export const RARIBLE_NFT_ADDRESS = "0x60F80121C31A0d46B5279700f9DF786054aa5eE5";
export const ORAI_NFT_ADDRESS = "0xA6CB55410Cff8f9eAD6C567b21c1BcB8DD6ecf72";
export const SWAP_ETH_NFT_ADDRESS = "0x3E32747cFf1620105aBA138c29d0c0B8E01C0d15";
export const FARMING_LOCK1 = "0x01bcAD3c4a23e4edD164e7298567F071Ff59560C";
export const FARMING_LOCK2 = "0x4d9925F0F1929345ED58d3c6feA24aA9ecaF6EBE";
export const KWT_FARMING_LOCK_ADDRESS = "0x487856EcA2e3eb9D0FbdD622d7d0000647722537";
export const KWT_FARMING_LOCK_2_ADDRESS = "0x86DC50Ad8719bF89f0Ea49C9A21aA328bCa18313";
export const KWT_ADDRESS = "0x257a8d1E03D17B8535a182301f15290F11674b53";
export const KWT_RELAY_ADDRESS = "0x1Cdb477AD8F6B0994782a6A39C57Bc1d6C5b78f7";
