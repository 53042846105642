// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import AIRI_LOGO from "src/assets/images/airi.png";
import { useWeb3React } from "@web3-react/core";
import { write, read } from "src/utils/web3Service";
import { CHAIN_ID } from "src/consts/web3";
import LoadingModal from "src/components/common/LoadingModal/LoadingModal";
import AIR_DROP_ABI from "src/utils/abi/airdrop.json";
import RELAY_ABI from "src/utils/abi/relay.json";
import { toast } from "react-toastify";
import formatNumber from "src/utils/formatNumber";
import createNetworkOrSwitch from "src/utils/createNetworkOrSwitch";
import cn from "classnames/bind";
import styles from "./AirDrop.module.scss";
import { RELAY_ADDRESS, AIR_DROP_ADDRESS } from "src/consts/address";

const cx = cn.bind(styles);

const AirDrop: React.FC<{}> = () => {
    const context = useWeb3React();
    const { account, chainId, library } = context;
    const [show, setShow] = useState(false);
    const [reward, setReward] = useState(0);
    const [loading, setLoading] = useState(false);
    const [stepLoading, setStepLoading] = useState(0);
    const [showModalLoading, setShowModalLoading] = useState(false);
    const [hash, setHash] = useState();

    const getReward = async () => {
        if (account) {
            const reward = await read("currentReward", AIR_DROP_ADDRESS, AIR_DROP_ABI, [account]);
            return reward / 10 ** 18;
        }
    };

    const getUserDetail = async () => {
        if (account) {
            const reward = await getReward();
            setReward(reward);
        }
    };

    const claimReward = async () => {
        try {
            if (!account) {
                toast.error("Please connect your BSC wallet");
            }
            if (chainId !== CHAIN_ID) {
                const error = await createNetworkOrSwitch();
                if (error) {
                    throw new Error("Please change network to Binance smart chain");
                }
            }
            setStepLoading(0);
            setShowModalLoading(true);
            const callback = (hash) => {
                setHash(hash);
                setStepLoading(1);
            };
            await write("claimAirdrop", library.provider, RELAY_ADDRESS, RELAY_ABI, account, [AIR_DROP_ADDRESS, account], callback);
            setReward(0);
            setStepLoading(2);
        } catch (error) {
            console.log(error);
            toast.error(error?.message || "An error occurred");
            setStepLoading(3);
        } finally {
            setLoading(false);
        }
    };

    useEffect(getUserDetail, [account]);

    return (
        <>
            <div className={cx("air-drop")} onClick={() => setShow(true)}>
                <div className={cx("air-drop-text")}> Airdrop</div>
            </div>
            <Modal
                show={show}
                dialogClassName={cx("air-drop-modal")}
                centered
                onHide={() => setShow(false)}
                backdrop="static"
                style={{
                    borderRadius: "8px",
                }}
            >
                <Modal.Body className={cx("modal-body")}>
                    <div className={cx("top-body")}>
                        <CancelIcon color="error" className={cx("icon-cancel")} onClick={() => setShow(false)} />
                    </div>
                    <div className={cx("body")}>
                        <div className={cx("announcement")}>Airdrop has stopped. Please claim all yours before 23:59UTC 20 July 2021</div>
                        <img src={AIRI_LOGO} className={cx("airi")} />
                        <div className={cx("amount")}>{reward ? formatNumber(Math.floor(reward * 10 ** 6) / 10 ** 6, 6, 3) : "0.000000"} AIRI</div>
                        <div className={cx("title")}>AIRI has arrived</div>
                        <span className={cx("description")}>
                            <span className={cx("description-text")}>Thanks for being part of the aiRight </span>
                            <a className={cx("community-link")} href="https://t.me/airight_io" target="_blank">
                                community
                            </a>
                        </span>
                        <button className={cx("button")} onClick={claimReward} disabled={loading || !reward}>
                            Claim
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            {showModalLoading && (
                <LoadingModal
                    show={true}
                    loading={loading}
                    title={"Claiming airdrop"}
                    stepLoading={stepLoading}
                    onHide={() => {
                        setShowModalLoading(false);
                        setHash(null);
                        setStepLoading(0);
                    }}
                    hash={hash}
                />
            )}
        </>
    );
};

export default AirDrop;
