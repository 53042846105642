import React from "react";
import cn from "classnames/bind";
import { createUltimatePagination } from "react-ultimate-pagination";
import styles from "./Pagination.module.scss";
import BackwardIcon from "src/components/icons/BackwardIcon";
import PreviousIcon from "src/components/icons/PreviousIcon";
import NextIcon from "src/components/icons/NextIcon";
import ForwardIcon from "src/components/icons/ForwardIcon";
const cx = cn.bind(styles);

const Page = (props: any) => {
    return (
        <button
            disabled={props.disabled}
            className={cx("page-link", { "page-link-active": props.isActive })}
            onClick={props.onClick}
        >
            <span className={cx("page-link-text")}>{props.value}</span>
        </button>
    );
};

const Ellipsis = (props: any) => {
    return (
        <button
            disabled={props.disabled}
            className={cx("page-link")}
            onClick={props.onClick}
        >
            <span className={cx("page-link-icon")}>...</span>
        </button>
    );
};

const FirstPageLink = (props: any) => {
    return (
        <button
            disabled={props.disabled}
            className={cx("page-link")}
            onClick={props.onClick}
        >
            <BackwardIcon className={cx("page-link-icon")} />
        </button>
    );
};

const PreviousPageLink = (props: any) => {
    return (
        <button
            disabled={props.disabled}
            className={cx("page-link")}
            onClick={props.onClick}
        >
            <PreviousIcon className={cx("page-link-icon")} />
        </button>
    );
};

const NextPageLink = (props: any) => {
    return (
        <button
            onClick={props.onClick}
            disabled={props.disabled}
            className={cx("page-link")}
        >
            <NextIcon className={cx("page-link-icon")} />
        </button>
    );
};

const LastPageLink = (props: any) => {
    return (
        <button
            onClick={props.onClick}
            disabled={props.disabled}
            className={cx("page-link")}
        >
            <ForwardIcon className={cx("page-link-icon")} />
        </button>
    );
};

const Wrapper = (props: any) => {
    return <div className={cx("pagination")}>{props.children}</div>;
};

const Pagination: React.FC<{
    currentPage: number;
    totalPages: number;
    onChange: any;
}> = ({ currentPage, totalPages, onChange }) => {
    if (totalPages <= 1) {
        return null;
    }
    const itemTypeToComponent = {
        PAGE: Page,
        ELLIPSIS: Ellipsis,
        FIRST_PAGE_LINK: FirstPageLink,
        PREVIOUS_PAGE_LINK: PreviousPageLink,
        NEXT_PAGE_LINK: NextPageLink,
        LAST_PAGE_LINK: LastPageLink,
    };
    const UltimatePagination = createUltimatePagination({
        itemTypeToComponent,
        WrapperComponent: Wrapper,
    });
    return (
        <UltimatePagination
            currentPage={currentPage}
            totalPages={totalPages < currentPage ? currentPage : totalPages}
            onChange={onChange}
        />
    );
};

export default Pagination;
