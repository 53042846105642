import React from 'react';
import cn from "classnames/bind";
import _ from "lodash";
import styles from "./CountDown.module.scss";

const cx = cn.bind(styles);

const CountDown: React.FC<{
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
}> = ({
    days,
    hours,
    minutes,
    seconds,
}) => {
        return (
            <div className={cx("countDown")}>
                <div className={cx("time")}>
                    <div className={cx("oneTime")}>
                        <div className={cx("number")}>{days}</div>
                        <div className={cx("text")}>Days</div>
                    </div>
                    <div className={cx("oneTime")}>
                        <div className={cx("number")}>{hours}</div>
                        <div className={cx("text")}>Hours</div>
                    </div>
                    <div className={cx("oneTime")}>
                        <div className={cx("number")}>{minutes}</div>
                        <div className={cx("text")}>Mins</div>
                    </div>
                    <div className={cx("oneTime")}>
                        <div className={cx("number")}>{seconds}</div>
                        <div className={cx("text")}>Secs</div>
                    </div>
                </div>
            </div>
        )
    }

export default CountDown;