import axios from "axios";
import configEnv from "../utils/config.env";

export const baseUrl = configEnv.REACT_APP_BASE_URL || "https://v1-api.airight.io";

export const GENERATE_NFT = "/assets/1/generate-nft";
export const IPFS_GATEWAY_URL = "https://gateway.ipfs.airight.io";
export const IPFS_AI_RIGHT_URL = "https://api.ipfs.airight.io";
export const AI_RIGHT_SIMILARITY = "https://100api.orai.dev";

axios.defaults.baseURL = baseUrl;
