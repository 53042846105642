import React from "react";

const ListTxIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path
                    d="M12.2505 2.18652H3.5036C3.02052 2.18652 2.62891 2.57814 2.62891 3.06122C2.62891 3.54429 3.02052 3.93591 3.5036 3.93591H12.2505C12.7336 3.93591 13.1252 3.54429 13.1252 3.06122C13.1252 2.57814 12.7336 2.18652 12.2505 2.18652Z"
                    fill="currentColor"
                />
                <path
                    d="M1.75428 3.06144C1.7533 2.82984 1.6605 2.60807 1.49625 2.44478C1.15509 2.10565 0.604091 2.10565 0.262934 2.44478C0.0986558 2.60807 0.00585634 2.82984 0.00489965 3.06144C-0.00163321 3.1181 -0.00163321 3.17534 0.00489965 3.232C0.0147946 3.289 0.0309217 3.34473 0.0530077 3.3982C0.0764878 3.44999 0.104287 3.49974 0.136104 3.54689C0.16751 3.59593 0.204166 3.64139 0.24544 3.68247C0.285239 3.72211 0.329219 3.75731 0.376644 3.78743C0.422702 3.82097 0.472641 3.84888 0.525342 3.87053C0.583317 3.9006 0.64512 3.92268 0.709027 3.93613C0.765691 3.94247 0.822928 3.94247 0.879592 3.93613C1.11057 3.93632 1.33225 3.84514 1.49625 3.68247C1.53753 3.64139 1.57418 3.59593 1.60559 3.54689C1.6374 3.49974 1.6652 3.44999 1.68868 3.3982C1.71659 3.34539 1.7386 3.28965 1.75428 3.232C1.76082 3.17534 1.76082 3.1181 1.75428 3.06144Z"
                    fill="currentColor"
                />
                <path
                    d="M1.75524 6.99747C1.76175 6.94081 1.76175 6.88357 1.75524 6.82691C1.74013 6.77046 1.7181 6.71612 1.68964 6.66509C1.66712 6.61143 1.63927 6.56016 1.60655 6.51202C1.57623 6.46353 1.53944 6.41938 1.49721 6.38082C1.15605 6.04168 0.605051 6.04168 0.263894 6.38082C0.0996155 6.54411 0.00681607 6.76587 0.00585938 6.99747C0.00755409 7.11274 0.0297767 7.22675 0.0714613 7.33423C0.0933833 7.38597 0.119733 7.43572 0.150184 7.48293C0.183449 7.53046 0.221553 7.57444 0.263894 7.61413C0.302544 7.65628 0.346661 7.69307 0.395098 7.72347C0.441156 7.75703 0.491068 7.78494 0.543795 7.80656C0.597124 7.82901 0.652913 7.84516 0.709987 7.85467C0.76594 7.86722 0.823205 7.8731 0.880552 7.87217C0.937216 7.8787 0.994453 7.8787 1.05112 7.87217C1.10677 7.86263 1.16111 7.84647 1.21294 7.82406C1.26708 7.80257 1.31847 7.77467 1.36601 7.74096C1.41444 7.71057 1.45856 7.67378 1.49721 7.63163C1.53936 7.59298 1.57615 7.54886 1.60655 7.50042C1.64017 7.45439 1.66808 7.40448 1.68964 7.35172C1.71952 7.29367 1.74158 7.23189 1.75524 7.16804C1.76197 7.11137 1.76197 7.05414 1.75524 6.99747Z"
                    fill="currentColor"
                />
                <path
                    d="M1.7553 10.9335C1.76178 10.8768 1.76178 10.8196 1.7553 10.7629C1.74018 10.7051 1.71815 10.6493 1.6897 10.5968C1.66622 10.545 1.63842 10.4952 1.6066 10.4481C1.5762 10.3996 1.53941 10.3555 1.49726 10.3169C1.15611 9.97772 0.605105 9.97772 0.263947 10.3169C0.221798 10.3555 0.185006 10.3996 0.154611 10.4481C0.122794 10.4952 0.094995 10.545 0.0715149 10.5968C0.0488276 10.65 0.0326731 10.7058 0.0234068 10.7629C0.0110791 10.819 0.00522964 10.8762 0.00591299 10.9335C0.00689702 11.1651 0.0996964 11.3869 0.263947 11.5502C0.302598 11.5923 0.346715 11.6291 0.395151 11.6595C0.441209 11.6931 0.491121 11.721 0.543849 11.7426C0.597178 11.765 0.652967 11.7812 0.710041 11.7907C0.765993 11.8033 0.823258 11.8091 0.880605 11.8082C0.937269 11.8147 0.994507 11.8147 1.05117 11.8082C1.10682 11.7987 1.16116 11.7825 1.21299 11.7601C1.26714 11.7386 1.31853 11.7107 1.36606 11.677C1.4145 11.6466 1.45861 11.6098 1.49726 11.5677C1.53941 11.529 1.5762 11.4849 1.6066 11.4365C1.64025 11.3905 1.66816 11.3405 1.6897 11.2878C1.71954 11.2297 1.7416 11.1679 1.7553 11.1041C1.76202 11.0474 1.76202 10.9902 1.7553 10.9335Z"
                    fill="currentColor"
                />
                <path
                    d="M13.1252 6.12305H3.5036C3.02052 6.12305 2.62891 6.51466 2.62891 6.99774C2.62891 7.48082 3.02052 7.87243 3.5036 7.87243H13.1252C13.6083 7.87243 13.9999 7.48082 13.9999 6.99774C13.9999 6.51466 13.6083 6.12305 13.1252 6.12305Z"
                    fill="currentColor"
                />
                <path
                    d="M9.1891 10.0591H3.5036C3.02052 10.0591 2.62891 10.4507 2.62891 10.9338C2.62891 11.4169 3.02052 11.8085 3.5036 11.8085H9.1891C9.67218 11.8085 10.0638 11.4169 10.0638 10.9338C10.0638 10.4507 9.67218 10.0591 9.1891 10.0591Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ListTxIcon;
