import React from "react";

const TabDownArrowIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='8' height='17' viewBox='0 0 8 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M4 11.5L0 7.5H8L4 11.5Z' fill='currentColor' />
        </svg>
    );
};

export default TabDownArrowIcon;
