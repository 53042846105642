import React from 'react';
import './AntImage.css';
import { Image } from 'antd';

const AntImage: React.FC<{
    src: any;
    width: any;
    height: any;
}> = ({
    src,
    width,
    height,
}) => {
        return (
            <Image src={src} width={width} height={height} />
        )
    }

export default AntImage;