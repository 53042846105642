import React from "react";

const TimesIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 6 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.499894 5.99674C0.401018 5.99672 0.304369 5.96739 0.222164 5.91244C0.139959 5.8575 0.0758896 5.77942 0.0380546 5.68807C0.000219561 5.59672 -0.00968207 5.4962 0.00960171 5.39923C0.0288855 5.30225 0.0764889 5.21317 0.146394 5.14324L5.14639 0.143243C5.24044 0.0508879 5.36715 -0.000591774 5.49896 5.13348e-06C5.63076 0.000602041 5.757 0.0532271 5.85021 0.14643C5.94341 0.239634 5.99604 0.365873 5.99663 0.497681C5.99723 0.629489 5.94575 0.756199 5.85339 0.850243L0.853394 5.85024C0.807028 5.89675 0.75193 5.93363 0.691264 5.95877C0.630599 5.98391 0.565562 5.99682 0.499894 5.99674Z"
                fill="currentColor"
            />
            <path
                d="M5.49967 5.99674C5.434 5.99682 5.36897 5.98391 5.3083 5.95877C5.24764 5.93363 5.19254 5.89675 5.14617 5.85024L0.146173 0.850243C0.0538176 0.756199 0.00233791 0.629489 0.00293482 0.497681C0.00353173 0.365873 0.0561568 0.239634 0.14936 0.14643C0.242563 0.0532271 0.368803 0.000602041 0.50061 5.13348e-06C0.632418 -0.000591774 0.759129 0.0508879 0.853173 0.143243L5.85317 5.14324C5.92308 5.21317 5.97068 5.30225 5.98996 5.39923C6.00925 5.4962 5.99935 5.59672 5.96151 5.68807C5.92368 5.77942 5.85961 5.8575 5.7774 5.91244C5.6952 5.96739 5.59855 5.99672 5.49967 5.99674Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default TimesIcon;
