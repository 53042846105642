//@ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import cn from "classnames/bind";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout/MainLayout";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Button from "src/components/common/Button/Button";
import styles from "./NftGalleryDetail.module.scss";
import nftExample from "src/assets/images/nftExample.png";
import NftCard from "./NftCard.tsx";
import SamoraiImage from "src/assets/images/samorai.png";
import { toast } from "react-toastify";
import GALLERY_ABI from "src/utils/abi/gallery.json";
import { read } from "src/utils/web3Service";
import { useWeb3React } from "@web3-react/core";
import _ from "lodash";
import REGISTER_ABI from "src/utils/abi/register.json";
import { PANCAKE_FACTORY_ADDRESS, REGISTER_ADDRESS, WBNB_ADDRESS, ZERO_ADDRESS } from "src/consts/address";
import axios from "axios";
import BscIcon from "src/assets/icons/bsc.svg";
import LoadingDialog from "src/components/common/LoadingDialog/LoadingDialog";
import { Modal, Tab, Tabs } from "react-bootstrap";
import PANCAKE_FACTORY_ABI from "src/utils/abi/pancake-factory.json";
import AuctionCard from "./AuctionCard.tsx";
import LoadingPage from "./LoadingPage.tsx";
import AntImage from "src/components/common/AntImage/AntImage";
import { SAMORAI_ADDRESS } from "src/consts/address";
import SpecPagination from "src/components/common/SpecPagination/SpecPagination";
import TransactionHistory from "src/components/common/TransactionHistory/TransactionHistory";
import { useGet } from "restful-react";
import NftGalleryTransactionHistory from "src/components/common/NftGalleryTransactionHistory/NftGalleryTransactionHistory";

const cx = cn.bind(styles);

const NftGalleryDetail: React.FC<{}> = ({}) => {
    const context = useWeb3React();
    const { account } = context;
    const history = useHistory();
    const { galleryId } = useParams<{ galleryId: string }>();
    const { id } = useParams();
    const [gallery, setGallery] = useState();
    const [selectedNft, setSelectedNft] = useState();
    const [listNFTs, setListNFTs] = useState();
    const [listAuction, setListAuction] = useState();
    const [loading, setLoading] = useState(true);
    const [activeTabKey, setActiveTabKey] = useState("history");
    const [assetsData, setAssetData] = useState<any>();
    const [price, setPrice] = useState();
    const pancakePoolAddr = useRef({});
    const location = useLocation();
    const [key, setKey] = useState();
    const [selectedAuction, setSelectedAuction] = useState();
    const [auctionIdx, setAuctionIdx] = useState();
    const [now, setNow] = useState(Date.now());
    const [visibleImg, setVisibleImg] = useState(false);
    const [balance, setBalance] = useState(0);
    const [checkUrl, setCheckUrl] = useState();

    useEffect(() => {
        if (account) {
            getBalance();
        }
    }, [account]);

    useEffect(() => {
        if (gallery) {
            getListNFTs();
            getListAuction();
            getPrice();
        }
    }, [gallery]);

    useEffect(() => {
        if ((listAuction && listAuction.length) || (listNFTs && listNFTs.length)) {
            checkURL();
        }
    }, [listNFTs, listAuction]);

    const checkURL = async () => {
        if (!listAuction || !listNFTs) return;
        let selectedAuction = await listAuction.filter((i) => i[3] == id && i[8] == 1)[0];
        let selectedNft = await listNFTs.filter((i, ind) => i.id == id)[0];

        if (selectedAuction) {
            setCheckUrl(true);
            setKey(true);
            setSelectedAuction(selectedAuction);
            setAuctionIdx(selectedAuction.idx);
            setLoading(false);
            return;
        }

        if (selectedNft) {
            setCheckUrl(true);
            setKey(false);
            setSelectedNft(selectedNft);
            setLoading(false);
            return;
        }

        history.push(`/nft-gallery/${galleryId}`);
        return toast.error("NFT is not exist");
    };

    const isExpired = (time) => {
        return time * 1000 < Date.now();
    };

    const getGallery = async () => {
        try {
            const gallery = await read("getInfos", galleryId, GALLERY_ABI, []);
            if (!gallery) {
                history.push(`/nft-gallery`);
                return toast.error("Gallery not exist");
            }
            setGallery(gallery);
        } catch (error) {
            console.log(error);
        }
    };

    const getBalance = async () => {
        try {
            const balance = await read("balanceOf", galleryId, GALLERY_ABI, [account]);
            setBalance(balance / 10 ** 18);
        } catch (error) {
            console.log(error);
        }
    };

    const getListNFTs = async () => {
        const numNFT = await getNftsLength();
        const getNFTs = [];
        const getTokenDatas = [];

        for (let i = 0; i < parseInt(numNFT); i++) {
            getNFTs.push(read("tokenInVault", galleryId, GALLERY_ABI, [i]));
        }
        const arrNFT = await Promise.all(getNFTs);
        const result = Array<{ factory: string; id: string }>();
        for (let i = 0; i < parseInt(numNFT); i++) {
            getTokenDatas.push(getTokenData(arrNFT[i].tokenFactory, arrNFT[i].tokenId));
        }
        const res = await Promise.all(getTokenDatas);
        const tokenInfo = await Promise.all(arrNFT.map((i) => read("tokenInfo", REGISTER_ADDRESS, REGISTER_ABI, [i.tokenId])));
        let isBlockChangePrice;
        if (account) {
            isBlockChangePrice = await Promise.all(
                arrNFT.map((i, idx) => read("blockChangeSellPrices", galleryId, GALLERY_ABI, [account, REGISTER_ADDRESS, i.tokenId]))
            );
        }
        arrNFT.forEach((nft, idx) =>
            result.push({
                factory: nft?.tokenFactory,
                id: nft?.tokenId,
                type: res[idx].nftType,
                owner: res[idx].owner,
                price: res[idx].price / 10 ** 18,
                uri: tokenInfo[idx].uri,
                name: tokenInfo[idx].name,
                description: tokenInfo[idx].description,
                isBlockChangePrice: isBlockChangePrice ? isBlockChangePrice[idx] : true,
            })
        );
        setListNFTs(result);
        console.log("selected nft result", result);
        // let selectedNft = await result.filter((i, ind) => i.id == id)[0];
        // console.log("selected nft", selectedNft);
        // if (key == "false") {
        //     setSelectedNft(selectedNft);
        //     setLoading(false);
        // }
    };

    const getNftsLength = async () => {
        return read("tokenInVaultLength", galleryId, GALLERY_ABI, []);
    };

    const getAuctionsLength = async () => {
        return read("auctionIdGlobal", galleryId, GALLERY_ABI, []);
    };

    const getListAuction = async () => {
        try {
            const auctionLength = await getAuctionsLength();
            const arr = new Array(Number(auctionLength)).fill();
            let listAuction = await Promise.all(arr.map((i, idx) => read("auctionIdToAuction", galleryId, GALLERY_ABI, [idx])));
            const tokenInfo = await Promise.all(
                listAuction.map((i) => read("tokenInfo", REGISTER_ADDRESS, REGISTER_ABI, [i.tokenId]))
            );
            const highestPrice = await Promise.all(arr.map((i, idx) => read("getCurrentPrice", galleryId, GALLERY_ABI, [idx])));
            const firstBids = await Promise.allSettled(arr.map((i, idx) => read("bidData", galleryId, GALLERY_ABI, [idx, 0])));
            listAuction = listAuction.map((i, idx) => ({
                ...i,
                firstBid: firstBids[idx].status === "fulfilled" ? firstBids[idx].value : undefined,
                idx: idx,
                uri: tokenInfo[idx].uri,
                name: tokenInfo[idx].name,
                description: tokenInfo[idx].description,
                highestPrice: highestPrice[idx] / 10 ** 18,
            }));
            setListAuction(listAuction);
            // let selectedAuction = await listAuction.filter((i) => i[3] == id && i[8] == 1)[0];
            // console.log("selected Auction", selectedAuction);
            // setSelectedAuction(selectedAuction);
            // selectedAuction && setAuctionIdx(selectedAuction.idx);
            // if (key == "true") {
            //     setLoading(false);
            // }
        } catch (error) {
            console.log(error);
        }
    };

    const getTokenData = async (tokenFactory, tokenId) => {
        return read("tokenData", galleryId, GALLERY_ABI, [tokenFactory, tokenId, 0]);
    };

    const getPrice = async () => {
        if (!pancakePoolAddr.current || !pancakePoolAddr.current[galleryId]) {
            pancakePoolAddr.current[galleryId] = await read("getPair", PANCAKE_FACTORY_ADDRESS, PANCAKE_FACTORY_ABI, [
                galleryId,
                WBNB_ADDRESS,
            ]);
        }

        if (pancakePoolAddr.current && pancakePoolAddr.current !== ZERO_ADDRESS) {
            let result = await axios.get(
                `https://api-liquidity.airight.io/v1/liquidity-history?addresses[0]=${pancakePoolAddr.current[galleryId]}`
            );
            if (result.status == 200) {
                setPrice(result.data?.data?.history[0]?.price);
            }
        }
    };

    useEffect(() => {
        getGallery();
    }, []);

    const isSamORAI = galleryId?.toLowerCase() === SAMORAI_ADDRESS.toLowerCase();

    const TabSection = (props: any) => {
        const { activeTabKey, tokenId, setActiveTabKey } = props;
        const pageSize = 10;
        const [bscTransactionPageId, setBscTransactionPageId] = useState(1);
        const [offerTransactionPageId, setOfferTransactionPageId] = useState(1);

        const {
            data: bscTransData,
            loading: bscTransLoading,
            error: bscTransError,
        } = useGet({
            path:
                `/bsc/transactions?size=${pageSize}&offset=${pageSize * (bscTransactionPageId - 1)}&filter=` +
                encodeURIComponent(`{"tokenId":"${parseInt(tokenId)}"}`),
        });

        const {
            data: offerTransData,
            loading: offerTransLoading,
            error: offerTransError,
        } = useGet({
            path:
                `/bsc/transactions?size=${pageSize}&offset=${pageSize * (offerTransactionPageId - 1)}&filter=` +
                encodeURIComponent(`{"events":["bid"],"tokenId":"${parseInt(tokenId)}"}`),
        });

        const renderBscTransactionsPagination = () => {
            return (
                <SpecPagination
                    currentPage={bscTransactionPageId}
                    dataSize={bscTransData?.length}
                    pageSize={pageSize}
                    onChange={(newPage: number) => {
                        setBscTransactionPageId(newPage);
                    }}
                />
            );
        };

        const renderOfferTransactionsPagination = () => {
            return (
                <SpecPagination
                    currentPage={offerTransactionPageId}
                    dataSize={offerTransData?.length}
                    pageSize={pageSize}
                    onChange={(newPage: number) => {
                        setOfferTransactionPageId(newPage);
                    }}
                />
            );
        };

        return (
            <div className={cx("nft-detail__tabs")}>
                <Tabs id='ai-model-details-tabs' activeKey={activeTabKey} onSelect={(key: any) => setActiveTabKey(key)}>
                    <Tab className={cx("nft-detail__tab-history")} eventKey='history' title='History'>
                        <div>
                            <div className={cx("scrollable")}>
                                <NftGalleryTransactionHistory
                                    pagination={renderBscTransactionsPagination}
                                    loading={bscTransLoading}
                                    data={bscTransData}
                                />
                            </div>
                        </div>
                    </Tab>
                    <Tab className={cx("nft-detail__tab-history")} eventKey='offers' title='Offers'>
                        <div>
                            <div className={cx("scrollable")}>
                                <NftGalleryTransactionHistory
                                    pagination={renderOfferTransactionsPagination}
                                    loading={offerTransLoading}
                                    data={offerTransData}
                                />
                            </div>
                        </div>
                    </Tab>
                    {/* <Tab eventKey='detail' title='Detail' className={cx("nft-detail__tab-detail")}>
                        <div>
                            <span>AI model: </span>
                            <Link to={`/ai-models/${nftDetail?.model?.id}`}>{nftDetail?.model?.name}</Link>
                        </div>
                        <div>
                            <span>Created at:</span> {moment(nftDetail?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                    </Tab> */}
                </Tabs>
            </div>
        );
    };

    return (
        <MainLayout isErc20 className={cx("main-layout")}>
            {loading ? (
                <LoadingPage />
            ) : (
                <div className='container'>
                    <div className={cx("nft-gallery-detail")}>
                        <div className={cx("img-left")}>
                            {/* <img
                                src={key == "true" ? selectedAuction.uri : selectedNft?.uri}
                                width="100%"
                                height="100%"
                                // onClick={() => setVisibleImg(true)}
                            /> */}
                            <AntImage width='100%' height='100%' src={key ? selectedAuction.uri : selectedNft?.uri} />
                            {/* <Image
                                width="100%"
                                height="100%"
                                src={key == "true" ? selectedAuction.uri : selectedNft?.uri}
                            /> */}
                        </div>
                        <div className={cx("info-right")}>
                            <div className={cx("title")}>{key ? selectedAuction.name : selectedNft.name}</div>
                            <div className={cx("price")}>
                                <div className={cx("coin")}>
                                    {key ? (selectedAuction[4] / 10 ** 18).toFixed(2) : selectedNft.price.toFixed(2)} {gallery[1]}
                                </div>
                                <div className={cx("dollar")}>
                                    ($
                                    {price
                                        ? key
                                            ? ((selectedAuction[4] / 10 ** 18) * price).toFixed(2)
                                            : (selectedNft.price * price).toFixed(2)
                                        : "--"}
                                    )
                                </div>
                            </div>
                            <div className={cx("description")}>{key ? selectedAuction.description : selectedNft.description}</div>
                            <div className={cx("owner")}>
                                <div className={cx("create")}>
                                    <div className={cx("subtitle")}>Owner</div>
                                    <div className={cx("avatar")}>
                                        <img src={BscIcon} width='40px' height='40px' className={cx("img")} />
                                        <div className={cx("name")}>{key ? selectedAuction?.seller : selectedNft?.owner}</div>
                                    </div>
                                </div>
                                <div className={cx("create")}>
                                    <div className={cx("subtitle")}>Gallery</div>
                                    <div className={cx("avatar")}>
                                        <img
                                            src={
                                                isSamORAI
                                                    ? SamoraiImage
                                                    : "https://cdn.discordapp.com/attachments/824920207947595788/846594861162299412/coming_1.png"
                                            }
                                            width='40px'
                                            height='40px'
                                            className={cx("img")}
                                        />
                                        <div className={cx("name")}>{gallery[1]}</div>
                                    </div>
                                </div>
                            </div>
                            {key ? (
                                <AuctionCard
                                    selectedAuction={selectedAuction}
                                    galleryId={galleryId}
                                    isExpired={isExpired}
                                    symbol={gallery ? gallery[1] : "GToken"}
                                    selectedNft={selectedNft}
                                    price={price}
                                    gallery={gallery}
                                    account={account}
                                    balance={balance}
                                    getBalance={getBalance}
                                    getListAuction={getListAuction}
                                    auctionIdx={auctionIdx}
                                    getListNFTs={getListNFTs}
                                />
                            ) : (
                                <NftCard
                                    listNFTs={listNFTs}
                                    galleryId={galleryId}
                                    selectedNft={selectedNft}
                                    symbol={gallery ? gallery[1] : "GToken"}
                                    price={price}
                                    gallery={gallery}
                                    account={account}
                                    balance={balance}
                                    getBalance={getBalance}
                                    getListNFTs={getListNFTs}
                                />
                            )}
                        </div>
                    </div>
                    {/* <div className={cx("table")}>
                        <Tabs activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <Tab eventKey="History" title="History">
                                History
                            </Tab>
                            <Tab eventKey="Detail" title="Detail">
                                Detail
                            </Tab>
                        </Tabs>
                    </div> */}
                    <TabSection
                        activeTabKey={activeTabKey}
                        tokenId={key ? selectedAuction?.tokenId : selectedNft.id}
                        setActiveTabKey={setActiveTabKey}
                    />
                </div>
            )}
        </MainLayout>
    );
};

export default NftGalleryDetail;
