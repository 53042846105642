// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import { RestfulProvider } from "restful-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { baseUrl } from "src/consts/api";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.css";

import App from "./App";
import store from "src/store/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import Wasm from "./lib/wasm";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Keplr from "./lib/keplr";
import configEnv from "./utils/config.env";

if (configEnv.REACT_APP_SENTRY_DNS && configEnv.NODE_ENV === "production") {
    Sentry.init({
        dsn: configEnv.REACT_APP_SENTRY_DNS,
        integrations: [new BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        // release: "airi-production@0.0.1",
        // environment: "production",
    });
    console.log("sentry init");
}

// polyfill for keplr extension
window.React = React;
(window as any).Buffer = require("buffer").Buffer;
window.process = require("process/browser");
if (!window.browser || !window.browser.storage) {
    const { LocalKVStore } = require("@keplr-wallet/common/build/kv-store/local");
    window.browser = { storage: { local: new LocalKVStore("orai") } };
}

function getLibrary(provider: any) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
}

ReactDOM.render(
    <RestfulProvider base={baseUrl}>
        <Web3ReactProvider getLibrary={getLibrary}>
            <Provider store={store}>
                <App />
            </Provider>
        </Web3ReactProvider>
    </RestfulProvider>,
    document.getElementById("root")
);

// global Wasm
window.Wasm = new Wasm();

// global Keplr
window.Keplr = new Keplr();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

if (configEnv.NODE_ENV !== "development") {
    console.log = () => {};
}
