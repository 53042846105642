import { CHAIN_ID_HEX, rpcUrls, blockExplorerUrls } from "src/consts/web3";

export default async () => {
    let ethereum = window.ethereum;
    if (!ethereum) return "reject";
    const data = [
        {
            chainId: CHAIN_ID_HEX,
            chainName: "Binance Smart Chain",
            nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
            },
            rpcUrls: [rpcUrls],
            blockExplorerUrls: [blockExplorerUrls],
        },
    ];

    try {
        const tx = await ethereum
        .request({ method: "wallet_addEthereumChain", params: data })
    } catch (error) {
        return error;
    }
};
