// @ts-nocheck

import React, { useState } from "react";
import cn from "classnames/bind";
import styles from "./components.module.scss";
import { read, write, sign } from "src/utils/web3Service";
import GALLERY_ABI from "src/utils/abi/gallery.json";
import RELAY_ABI from "src/utils/abi/relay.json";
import { RELAY_ADDRESS, REGISTER_ADDRESS } from "src/consts/address";
import Web3 from "web3";
import LoadingModal from "src/components/common/LoadingModal/LoadingModal";
import { CHAIN_ID } from "src/consts/web3";
import { useWeb3React } from "@web3-react/core";
import createNetworkOrSwitch from "src/utils/createNetworkOrSwitch";
import { toast } from "react-toastify";
import nftExample from "src/assets/images/nftExample.png";
import { useHistory } from "react-router-dom";

const cx = cn.bind(styles);

const NftCollection: React.FC<{
    account;
    galleryId;
    selectedNft;
    balance;
    getListNFTs;
    getBalance;
    onHide;
    symbol;
    price;
    gallery;
}> = ({
    account,
    galleryId,
    selectedNft,
    balance,
    getListNFTs,
    getBalance,
    onHide,
    symbol,
    price,
    gallery,
}) => {
        const context = useWeb3React();
        const { chainId, library } = context;
        const [stepLoading, setStepLoading] = useState(0);
        const [showModalLoading, setShowModalLoading] = useState(false);
        const [loading, setLoading] = useState(false);
        const [hash, setHash] = useState();
        const history = useHistory();

        let withdraw = async () => {
            try {
                if (!account) {
                    return toast.error("Please connect your wallet");
                }
                if (selectedNft.price > balance) {
                    return toast.error("Not enough balance");
                }
                setLoading(true);
                setStepLoading(0);
                setShowModalLoading(true);
                const callback = (hash) => {
                    setHash(hash);
                    setStepLoading(1);
                };
                if (chainId !== CHAIN_ID) {
                    if (chainId !== CHAIN_ID) {
                        const error = await createNetworkOrSwitch();
                        if (error) {
                            throw new Error(
                                "Please change network to Binance smart chain"
                            );
                        }
                    }
                }
                const allowance = await read("allowance", galleryId, GALLERY_ABI, [
                    account,
                    galleryId,
                ]);
                if (allowance === "0") {
                    const deadline = Math.floor(Date.now() / 1000) + 30 * 60;
                    const nonce = await read("nonces", galleryId, GALLERY_ABI, [
                        account,
                    ]);
                    const contractName = await read(
                        "NAME",
                        galleryId,
                        GALLERY_ABI,
                        []
                    );
                    console.log("contract name ", contractName);

                    const EIP712Domain = [
                        { name: "name", type: "string" },
                        { name: "version", type: "string" },
                        { name: "chainId", type: "uint256" },
                        { name: "verifyingContract", type: "address" },
                    ];

                    const domain = {
                        name: contractName,
                        version: "1",
                        chainId: CHAIN_ID,
                        verifyingContract: galleryId,
                    };
                    const Data = [
                        { name: "spender", type: "address" },
                        { name: "amount", type: "uint256" },
                        { name: "deadline", type: "uint256" },
                        { name: "nonce", type: "uint256" },
                    ];

                    const message = {
                        spender: galleryId,
                        amount: Web3.utils.toWei("999999999999", "ether"),
                        deadline,
                        nonce,
                    };
                    const data = JSON.stringify({
                        types: {
                            EIP712Domain,
                            Data,
                        },
                        domain,
                        primaryType: "Data",
                        message,
                    });
                    const signature = await sign(account, data, library.provider);
                    const { r, s, v } = signature;
                    await write(
                        "approvePermit",
                        library.provider,
                        RELAY_ADDRESS,
                        RELAY_ABI,
                        account,
                        [
                            galleryId,
                            galleryId,
                            Web3.utils.toWei("999999999999", "ether"),
                            deadline,
                            nonce,
                            v,
                            r,
                            s,
                        ]
                    );
                }

                const deadline = Math.floor(Date.now() / 1000) + 30 * 60;
                const nonce = await read("nonces", galleryId, GALLERY_ABI, [
                    account,
                ]);
                const contractName = await read("NAME", galleryId, GALLERY_ABI, []);
                console.log("contract name ", contractName);

                const EIP712Domain = [
                    { name: "name", type: "string" },
                    { name: "version", type: "string" },
                    { name: "chainId", type: "uint256" },
                    { name: "verifyingContract", type: "address" },
                ];

                const domain = {
                    name: contractName,
                    version: "1",
                    chainId: CHAIN_ID,
                    verifyingContract: galleryId,
                };

                const Data = [
                    { name: "nftFactory", type: "address" },
                    { name: "tokenId", type: "uint256" },
                    { name: "index", type: "uint256" },
                    { name: "deadline", type: "uint256" },
                    { name: "nonce", type: "uint256" },
                ];

                const message = {
                    nftFactory: REGISTER_ADDRESS,
                    tokenId: parseInt(selectedNft.id),
                    index: 0,
                    deadline,
                    nonce,
                };
                const data = JSON.stringify({
                    types: {
                        EIP712Domain,
                        Data,
                    },
                    domain,
                    primaryType: "Data",
                    message,
                });
                const signature = await sign(account, data, library.provider);
                const { r, s, v } = signature;
                await write(
                    "withdrawPermit",
                    library.provider,
                    RELAY_ADDRESS,
                    RELAY_ABI,
                    account,
                    [
                        galleryId,
                        selectedNft.factory,
                        parseInt(selectedNft.id),
                        0,
                        deadline,
                        nonce,
                        v,
                        r,
                        s,
                    ],
                    callback
                );
                getListNFTs();
                getBalance();
                setStepLoading(2);
                history.push(`/nft-gallery`);
            } catch (error) {
                console.log(error);
                setStepLoading(3);
                toast.error(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className={cx("card-content")}>
                <div className={cx("content")}>
                    <div className={cx("left")} style={{marginTop: '-20px'}}>
                        {/* <div className={cx("title")}>
                            <span>Highhest bid by</span>
                            <span className={cx("name")}> Lorem Ipsum</span>
                        </div> */}
                        <div className={cx("avatar")}>
                            {/* <img src={nftExample} width="50px" height="50px" className={cx("img")} /> */}
                            <div className={cx("price")}>
                                <div className={cx("coin")}>{selectedNft.price.toFixed(2)} {gallery[1]}</div>
                                <div className={cx("dollar")}>(${price ? (selectedNft.price * price).toFixed(2) : "--"})</div>
                                <div style={{ paddingTop: '5px' }}>Balance: {balance.toFixed(2)}{" "}{gallery[1]}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    className={cx("submit")}
                    onClick={withdraw}
                    disabled={loading}
                >
                    Withdraw
                </button>

                {showModalLoading && (
                    <LoadingModal
                        show={true}
                        loading={loading}
                        title="Withdrawing NFT"
                        stepLoading={stepLoading}
                        onHide={() => {
                            setShowModalLoading(false);
                            setHash(null);
                            setStepLoading(0);
                        }}
                        hash={hash}
                        hideParent={onHide}
                    />
                )}
            </div>
        );
    };

export default NftCollection;
