import { network } from "src/lib/config/networks";
import { ExecuteInstruction, SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { Decimal } from "@cosmjs/math";
import { GasPrice } from "@cosmjs/stargate";
import messagesErrors from "src/consts/messages";
import _ from "lodash";
import { AccountData, Coin, OfflineDirectSigner, OfflineSigner } from "@cosmjs/proto-signing";
import axios from "axios";
import configEnv from "../utils/config.env";

export const broadcastModeObj = {
    BROADCAST_MODE_BLOCK: "BROADCAST_MODE_BLOCK",
    BROADCAST_MODE_ASYNC: "BROADCAST_MODE_ASYNC",
    BROADCAST_MODE_SYNC: "BROADCAST_MODE_SYNC",
    BROADCAST_MODE_UNSPECIFIED: "BROADCAST_MODE_UNSPECIFIED",
};

type ClientData = {
    account: AccountData;
    client: SigningCosmWasmClient;
};

type Cosmos = {
    chainId: string;
};

export default class Wasm {
    public denom: string;
    public prefix: string;
    public chainId: string;

    // LEGACY to support backward compabilitiy for components
    public cosmos: Cosmos;

    constructor() {
        this.denom = network.denom;
        this.prefix = network.prefix;
        this.chainId = network.chainId;
        this.cosmos = {
            chainId: this.chainId,
        };
    }

    getClientData = async (wallet: OfflineSigner | OfflineDirectSigner, denom?: string, prefix?: string): Promise<ClientData> => {
        const [firstAccount] = await wallet.getAccounts();

        const client = await SigningCosmWasmClient.connectWithSigner(network.rpc as string, wallet, {
            gasPrice: new GasPrice(Decimal.fromUserInput("0", 6), denom || this.denom),
            prefix: prefix || this.prefix,
        });

        return { account: firstAccount, client };
    };

    collectWallet = async () => {
        const keplr = await window.Keplr.getKeplr();
        if (!keplr) {
            throw messagesErrors.INSTALL_KEPLR_FIRST;
        }
        return await keplr.getOfflineSignerAuto(this.chainId);
    };

    execute = async (address: string, inputData: string, funds: Coin[]) => {
        const wallet = await this.collectWallet();

        const { account, client } = await this.getClientData(wallet);

        const input = JSON.parse(inputData);
        const result = await client.execute(account.address, address, input, "auto", undefined, funds);
        console.log("result execute contract: ", result);
        // LEGACY: backward compatibility for component files => need to return tx_response object
        return { ...result, tx_response: { txhash: result.transactionHash } };
    };

    // LEGACY: support functions that are used in component files
    /**
     * get an object containing marketplace and ow721 contract addresses
     * @returns ContractAddress
     */
    get contractAddresses(): ContractAddress {
        return {
            marketplace: configEnv.REACT_APP_MARKET_PLACE_721_CONTRACT,
            ow721: configEnv.REACT_APP_NFT_TOKEN_CONTRACT,
            lock: configEnv.REACT_APP_LOCK_CONTRACT_ADDR,
            auction: configEnv.REACT_APP_AUCTION_CONTRACT,
            ow1155: configEnv.REACT_APP_NFT_1155_TOKEN_CONTRACT,
            marketplace1155: configEnv.REACT_APP_MARKET_PLACE_1155_CONTRACT,
        };
    }

    async query(address: string, input: string) {
        const param = Buffer.from(input).toString("base64");
        // TODO: need to filter old & new cosmwasm version
        return this.get(`/cosmwasm/wasm/v1/contract/${address}/smart/${param}`);
    }

    async get(url: string) {
        return axios
            .get(`${network.lcd}${url}`)
            .then((res) => res.data)
            .catch((err) => err.response.data);
    }

    executeMultipleTransaction = async (msgs: ExecuteInstruction[]) => {
        console.log("msgs: ", msgs);
        const wallet = await this.collectWallet();
        const { account, client } = await this.getClientData(wallet);
        const result = await client.executeMultiple(account.address, msgs, "auto");
        console.log("result execute multiple transactions: ", result);
        return { ...result, tx_response: { txhash: result.transactionHash } };
    };
}
