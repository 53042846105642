import React from "react";
import { useSelector } from "react-redux";
import { selectThemeId } from "./store/slices/activeThemeSlice";
import { themes } from "./consts/theme";

const GlobalStyles = React.memo(() => {
    const activeThemeId = useSelector(selectThemeId);
    const theme = themes.byIds[activeThemeId];

    return (
        <style>
            {`:root{
              --common-textColor: ${theme?.data?.common?.textColor};
              --common-textColorGray1: ${theme?.data?.common?.textColorGray1};
              --common-textColorPlace: ${theme?.data?.common?.textColorPlace};
              --common-linkColor: ${theme?.data?.common?.linkColor};
              --common-activeTabColor: ${theme?.data?.common?.activeTabColor};
              --common-activeTabTextColor: ${theme?.data?.common?.activeTabTextColor};
              --common-buttonTextColor: ${theme?.data?.common?.buttonTextColor};
              --common-buttonBackgroundColor: ${theme?.data?.common?.buttonBackgroundColor};
              --common-backgroundColor: ${theme.data.common.backgroundColor};
              --common-backgroundColorGray: ${theme.data.common.backgroundColorGray};
              --common-containerBackgroundColor: ${theme?.data?.common?.containerBackgroundColor};
              --common-blockItemBackgroundColor: ${theme?.data?.common?.blockItemBackgroundColor};
              --common-blockItemBackgroundColorDarken: ${theme?.data?.common?.blockItemBackgroundColorDarken};
              --common-borderColor: ${theme?.data?.common?.borderColor};
              --common-menuBorderColor: ${theme?.data?.common?.menuBorderColor};
              --common-headerTextColor: ${theme?.data?.common?.headerTextColor};
              --common-paginationTextColor: ${theme?.data?.common?.paginationTextColor};
              --common-paginationActiveTextColor: ${theme?.data?.common?.paginationActiveTextColor};
              --common-placeholderTextColor: ${theme?.data?.common?.placeholderTextColor};
              --common-resultIconSuccessColor: ${theme?.data?.common?.resultIconSuccessColor};
              --common-resultIconFailureColor: ${theme?.data?.common?.resultIconFailureColor};
              --common-resultIconPendingColor: ${theme?.data?.common?.resultIconPendingColor};
              --common-gradientActiveTextColor_1: ${theme?.data?.common?.gradientActiveTextColor_1};
              --common-gradientActiveTextColor_2: ${theme?.data?.common?.gradientActiveTextColor_2};
              --common-gradientActiveBackgroundColor_1: ${theme?.data?.common?.gradientActiveBackgroundColor_1};
              --common-gradientActiveBackgroundColor_2: ${theme?.data?.common?.gradientActiveBackgroundColor_2};
              --common-gradientTextColor_1: ${theme?.data?.common?.gradientTextColor_1};
              --common-gradientTextColor_2: ${theme?.data?.common?.gradientTextColor_2};
              --common-usdCurrencyBorderColor: ${theme?.data?.common?.usdCurrencyBorderColor};
              --common-usdCurrencyTextColor: ${theme?.data?.common?.usdCurrencyTextColor};
              --common-hoverBackgroundColor: ${theme?.data?.common?.hoverBackgroundColor};
              --common-hoverBackgroundColorGray: ${theme?.data?.common?.hoverBackgroundColorGray};
              --common-oraiDenomTextColor: ${theme?.data?.common?.oraiDenomTextColor};
              --common-underlineColor: ${theme?.data?.common?.underlineColor};
              --common-sliderTextBackgroundColor: ${theme?.data?.common?.sliderTextBackgroundColor};
              --common-scrollBarBackgroundColor: ${theme?.data?.common?.scrollBarBackgroundColor};
              --common-notificationIconColor: ${theme?.data?.common?.notificationIconColor};
              --common-notificationIconHoverColor: ${theme?.data?.common?.notificationIconHoverColor};
              --common-notificationUnreadColor: ${theme?.data?.common?.notificationUnreadColor};
              --common-notificationIconBackgroundHoverColor: ${theme?.data?.common?.notificationIconBackgroundHoverColor};
              --common-notificationActionTextColor: ${theme?.data?.common?.notificationActionTextColor};
              --common-priceTextColor: ${theme?.data?.common?.priceTextColor};
              --common-modal-modalCommonTextColor: ${theme?.data?.common?.modal?.modalCommonTextColor};
              --common-modal-modalCommonBorderColor: ${theme?.data?.common?.modal?.modalCommonBorderColor};
              --common-modal-modalCommonBackgroundColor: ${theme?.data?.common?.modal?.modalCommonBackgroundColor};
              --common-modal-modalSliderColor: ${theme?.data?.common?.modal?.modalSliderColor};
              --common-modal-buttonCancelBackgroundColor: ${theme?.data?.common?.modal?.buttonCancelBackgroundColor};
              --common-modal-buttonSubmitBackgroundColor: ${theme?.data?.common?.modal?.buttonSubmitBackgroundColor};
              --common-nftDetails-activeTabColor: ${theme?.data?.common?.nftDetails?.activeTabColor};
              --common-nftDetails-tableHeaderColor: ${theme?.data?.common?.nftDetails?.tableHeaderColor};
              --common-nftDetails-tableBorderColor: ${theme?.data?.common?.nftDetails?.tableBorderColor};
              --common-nftDetails-idTextColor: ${theme?.data?.common?.nftDetails?.idTextColor};
              --common-nftDetails-descriptionTextColor: ${theme?.data?.common?.nftDetails?.descriptionTextColor};
              --common-nftDetails-collectionTextColor: ${theme?.data?.common?.nftDetails?.collectionTextColor};
              --common-nftDetails-burnButtonBackgroundColor: ${theme?.data?.common?.nftDetails?.burnButtonBackgroundColor};
              --common-nftDetails-blockItemBackgroundColor: ${theme?.data?.common?.nftDetails?.blockItemBackgroundColor};
              --common-nftDetails-tabTextColor: ${theme?.data?.common?.nftDetails?.tabTextColor};
              --common-nftDetails-quantityBackgroundColor: ${theme?.data?.common?.nftDetails?.quantityBackgroundColor};
              --common-nftDetails-quantityTextColor: ${theme?.data?.common?.nftDetails?.quantityTextColor};
              --common-nftDetails-separateLineColor: ${theme?.data?.common?.nftDetails?.separateLineColor};
              --common-wallet-headerBackgroundColor: ${theme?.data?.common?.wallet?.headerBackgroundColor};
              --common-wallet-dropdownBackgroundColor: ${theme?.data?.common?.wallet?.dropdownBackgroundColor};
              --common-wallet-borderColor: ${theme?.data?.common?.wallet?.borderColor};
              --common-wallet-usdDenomTextColor: ${theme?.data?.common?.wallet?.usdDenomTextColor};
              --common-wallet-darkThemeTextColor: ${theme?.data?.common?.wallet?.darkThemeTextColor};
              --common-wallet-borderColorButton: ${theme?.data?.common?.wallet?.borderColorButton};
              --common-wallet-textColor: ${theme?.data?.common?.wallet?.textColor};
              --common-profile-dropdownHoverBackgroundColor: ${theme?.data?.common?.profile?.dropdownHoverBackgroundColor};
              --common-profile-dropdownActiveItemColor: ${theme?.data?.common?.profile?.dropdownActiveItemColor};
              --common-profile-tabShadowLeftColor: ${theme?.data?.common?.profile?.tabShadowLeftColor};
              --common-profile-tabShadowRightColor: ${theme?.data?.common?.profile?.tabShadowRightColor};
              --common-mint-blockItemColor: ${theme?.data?.common?.mint?.blockItemColor};
              --common-mint-uploadButtonColor: ${theme?.data?.common?.mint?.uploadButtonColor};
              --common-landing-badgeColor: ${theme?.data?.common?.landing?.badgeColor};
              --common-landing-badgeTextColor: ${theme?.data?.common?.landing?.badgeTextColor};
              --common-landing-topCollectorColor: ${theme?.data?.common?.landing?.topCollectorColor};
              --common-landing-badgeBorderColor: ${theme?.data?.common?.landing?.badgeBorderColor};
              --common-landing-sortColor: ${theme?.data?.common?.landing?.sortColor};
              --common-landing-inputBorderColor: ${theme?.data?.common?.landing?.inputBorderColor};
              --common-mediaKit-descriptionTextColor: ${theme?.data?.common?.mediaKit?.descriptionTextColor};
              --common-mediaKit-cardDescriptionTextColor: ${theme?.data?.common?.mediaKit?.cardDescriptionTextColor};
              --common-profile-borderPrivateDisplayColor: ${theme?.data?.common?.profile?.borderPrivateDisplayColor};
              --common-profile-textColorGray1: ${theme?.data?.common?.profile?.textColorGray1};
              --common-nftStaking-informationBackgroundColor: ${theme?.data?.common?.nftStaking?.informationBackgroundColor};
              --common-nftStaking-poolBackgroundColor: ${theme?.data?.common?.nftStaking?.poolBackgroundColor};   
              --common-nftStaking-borderColor: ${theme?.data?.common?.nftStaking?.borderColor};
              --common-nftStaking-backgroundGrayColor: ${theme?.data?.common?.nftStaking?.backgroundGrayColor};
              --common-navBar-textColorNavBarItem: ${theme?.data?.common?.navBar?.navBarItemColor};
              --common-navBar-borderColor: ${theme?.data?.common?.navBar?.borderColor};
              --common-navBar-backgroundColorItemNavbar: ${theme?.data?.common?.navBar?.backgroundColorItemNavbar};
              --common-navBar-backgroundColorNavbar: ${theme?.data?.common?.navBar?.backgroundNavBar};
              --common-marketPlace-backgroundColorGray: ${theme?.data?.common?.marketPlace?.backgroundColorGray};
              --common-marketPlace-textColorItemCategory: ${theme?.data?.common?.marketPlace?.textColorItemCategory};
              --common-marketPlace-textColorItemCategoryActive: ${theme?.data?.common?.marketPlace?.textColorItemCategoryActive};
              --common-marketPlace-textColorButton: ${theme?.data?.common?.marketPlace?.textColorButton};
              --common-marketPlace-backgroundIconFilter: ${theme?.data?.common?.marketPlace?.backgroundIconFilter};
              --common-marketPlace-textColorToolbarDropdown: ${theme?.data?.common?.marketPlace?.textColorToolbarDropdown};
              --common-marketPlace-backgroundToolbar: ${theme?.data?.common?.marketPlace?.backgroundToolbar};
              --common-simmilarityCheckReport-textColor: ${theme?.data?.common?.simmilarityCheckReport?.textColor};
              --common-simmilarityCheckReport-backgroundColorGray: ${theme?.data?.common?.simmilarityCheckReport?.backgroundColorGray};
              --common-simmilarityCheckReport-textColorGray: ${theme?.data?.common?.simmilarityCheckReport?.textColorGray};
              --common-simmilarityCheckReport-backgroundColorGray2: ${theme?.data?.common?.simmilarityCheckReport?.backgroundColorGray2};
              --common-simmilarityCheckReport-backgroundColorGray3: ${theme?.data?.common?.simmilarityCheckReport?.backgroundColorGray3};
              --common-simmilarityCheckReport-textColorGray1: ${theme?.data?.common?.simmilarityCheckReport?.textColorGray1};
              --common-simmilarityCheckReport-backGroundColorTag: ${theme?.data?.common?.simmilarityCheckReport?.backGroundColorTag};
              --common-simmilarityCheckReport-textColorTag: ${theme?.data?.common?.simmilarityCheckReport?.textColorTag};
              --common-simmilarityCheckReport-backgroundModalCheckReport: ${theme?.data?.common?.simmilarityCheckReport?.backgroundModalCheckReport};
              --common-simmilarityCheckReport-backgroundDemoCheckReport: ${theme?.data?.common?.simmilarityCheckReport?.backgroundDemoCheckReport};
              --common-simmilarityCheckReport-backgroundCardDemoCheckReport: ${theme?.data?.common?.simmilarityCheckReport?.backgroundCardDemoCheckReport};
              --common-simmilarityCheckReport-backgroundTopSimilarNFTItem: ${theme?.data?.common?.simmilarityCheckReport?.backgroundTopSimilarNFTItem};
              --common-simmilarityCheckReport-backgroundColorStep: ${theme?.data?.common?.simmilarityCheckReport?.backgroundColorStep};
              --common-swap-backgroundColorGray: ${theme?.data?.common?.swap?.backgroundColorGray};
              --common-swap-backgroundColorGray2: ${theme?.data?.common?.swap?.backgroundColorGray2};
              --common-swap-borderColor: ${theme?.data?.common?.swap?.borderColor};
              --common-swap-backgroundColorStep: ${theme?.data?.common?.swap?.backgroundColorStep};
              --common-swap-backgroundColorGray3: ${theme?.data?.common?.swap?.backgroundColorGray3};
              }`}
        </style>
    );
});

export default GlobalStyles;
