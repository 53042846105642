import axios from 'axios';
import Web3 from 'web3';
import { RPC_URLS } from 'src/utils/connectors';
import { CHAIN_ID } from 'src/consts/web3';
import { splitSignature } from '@ethersproject/bytes';

export const write = async (method, provider, address, abi, account, params, callback, value = 0) => {
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(abi, address);
    let response, sendObj;
    if (value > 0) sendObj = { from: account, value: value };
    else sendObj = { from: account };
    await contract.methods[method](...params)
        .send(sendObj)
        .on('transactionHash', (hash) => {
            console.log('txhash', hash);
            if (callback) {
                callback(hash);
            }
        })
        .on('receipt', (receipt) => {
            console.log(receipt);
            response = receipt;
        });
    return response;
};

export const sign = async (account, data, provider) => {
    const web3 = new Web3(provider);
    let res = await provider.send('eth_signTypedData_v4', [account, data]);
    if (res.result) {
        res = res.result;
    }
    return splitSignature(res);
};

export const read = async (method, address, abi, params, from = null, chainId = CHAIN_ID) => {
    try {
        const web3 = new Web3(RPC_URLS[chainId]);
        const contract = new web3.eth.Contract(abi, address);
        if (from)
            return await contract.methods[method](...params).call({
                from: from
            });
        return await contract.methods[method](...params).call();
    } catch (error) {
        // console.log("error is: " ,error, method, params);
    }
};

export const getEthBalance = (address) => {
    const web3 = new Web3(RPC_URLS[CHAIN_ID]);
    try {
        return web3.eth.getBalance(address);
    } catch {
        return Promise.resolve(0);
    }
};

export const readTestnet = async (method, address, abi, params, from = null) => {
    try {
        const web3 = new Web3(RPC_URLS[97]);
        const contract = new web3.eth.Contract(abi, address);
        if (from)
            return await contract.methods[method](...params).call({
                from: from
            });
        else return await contract.methods[method](...params).call();
    } catch (error) {
        console.log(error);
    }
};

export const estimateGasPrice = () => {
    const data = JSON.stringify({
        jsonrpc: '2.0',
        method: 'eth_gasPrice',
        params: [],
        id: 1
    });
    const config = {
        method: 'post',
        url: 'https://mainnet.infura.io/v3/98a353b7d2c74317b66a017bc20563a2',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    return axios(config);
};
