import styles from "./index.module.scss";
import cn from "classnames/bind";
import MagicIcon from "src/assets/icons/MagicIcon.svg";
const cx = cn.bind(styles);

const Banner = () => {
  return (
    <div className={cx("banner-wrap")}>
      <div className={cx("box")}>
        <img style={{ maxWidth: "20px" }} src={MagicIcon} alt="icon" />
        <p className="">Free online ai art generator & models hosting site!</p>
        <a href="https://airight.io" target="_blank" rel="noopener noreferrer">
          Try now
        </a>
      </div>
    </div>
  );
};

export default Banner;
