import { createSlice } from "@reduxjs/toolkit";
import { IState, IAlert } from "src/types/redux";

const initialState: IAlert = {
    isVisible: false,
    variant: "success",
    title: "",
    message: "",
    onHide: null,
};

export const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        showAlertBox: (state: IAlert, action) => {
            state.isVisible = true;
            state.variant = action.payload.variant;
            state.title = action.payload.title;
            state.message = action.payload.message;
            state.onHide = action.payload?.onHide ?? null;
        },
        hideAlertBox: (state: IAlert) => {
            return Object.assign(state, initialState);
        },
    },
});

export const { showAlertBox, hideAlertBox } = alertSlice.actions;

export const selectAlert = (state: IState): IAlert => {
    return state.alert;
};

export default alertSlice.reducer;
