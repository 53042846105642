
import React, { ReactNode } from "react";
import cn from "classnames/bind";
import MainLayout from "src/components/layouts/MainLayout/MainLayout";
import styles from "./NotFound.module.scss";
import { Link } from "react-router-dom";

const cx = cn.bind(styles);

const NotFound: React.FC<{}> = () => {
    return (
        <MainLayout className={cx("main-layout")}>
            <div className={cx("background")}>
                <div className={`container ${cx("wrapper")}`}>
                    <div className={cx("text")}>
                      <h1>404</h1>
                      <h4>Sorry, the page you visited does not exist.</h4>
                      <Link to={'/'} className={cx("back")}>
                        BACK TO HOMEPAGE
                      </Link>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default NotFound;
