import networks from "./config/networks";
import configEnv from "src/utils/config.env";

// export const isTestnet = configEnv.REACT_APP_IS_TEST_NET || localStorage?.getItem("network") === networks.TESTNET;
// export const isTestnet = true;
const config = Object.freeze({
	SCAN_API: configEnv.REACT_APP_SCAN_API || configEnv.REACT_APP_API_DEV || "https://api.scan.orai.io/v1",
	LCD_API: configEnv.REACT_APP_LCD || "https://lcd.orai.io",
});

export default config;
