import React from 'react';
import bgr from 'src/assets/images/bg_main.png';
import { CircularProgress } from '@material-ui/core';

const LoadingPage = () => {
    return (
        <div style={{
            backgroundImage: `url(${bgr})`,
            width: "100%",
            height: "100vh",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <div style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'center', color: '#fff' }}>
                <span style={{ paddingRight: '30px' }}>Please wait</span>
                <CircularProgress size="50px"></CircularProgress>
            </div>
        </div>
    )
}

export default LoadingPage;