import React from "react";
import { Modal } from "react-bootstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import cn from "classnames/bind";
import styles from "./LoadingModal.module.scss";
import upload from "src/assets/icons/upload.svg";
import loadingProcess from "src/assets/icons/loading-process.svg";
import warning from "src/assets/icons/warning.svg";
import { Button } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

const cx = cn.bind(styles);

const LoadingModal: React.FC<{
    show: boolean;
    loading: boolean;
    title: any;
    stepLoading: number;
    onHide: any;
    hash: any;
    hideParent: any;
    path: string;
    stakePath: string;
}> = ({ show, loading, title, stepLoading, onHide, hash, hideParent, path = "/", stakePath = "/" }) => {
    const history = useHistory();

    return (
        <Modal
            show={show}
            dialogClassName={cx("loading-modal")}
            centered
            onHide={() => {
                if (!loading) {
                    if (stepLoading === 2 && hideParent) {
                        hideParent();
                    }
                    onHide();
                }
            }}
            backdrop="static"
            style={{
                borderRadius: "8px",
            }}
        >
            <Modal.Body className={cx("modal-body")}>
                <div className={cx("top-body")}>
                    <CancelIcon
                        color="error"
                        className={cx("icon-cancel")}
                        onClick={() => {
                            if (!loading) {
                                if (stepLoading === 2 && hideParent) {
                                    hideParent();
                                }
                                onHide();
                            } else if (stepLoading === 1) {
                                onHide();
                            }
                        }}
                    />
                </div>
                {stepLoading === 2 ? (
                    <div>
                        <div className={cx("center-body")}>
                            <img src={upload} alt="upload-icon" />
                        </div>
                        <div className={cx("bottom-body")}>
                            <div className={cx("title")}>Transaction confirmed</div>
                            <br />
                            {hash && (
                                <Button className={cx("MuiButton-root")} size="large">
                                    <a href={`https://bscscan.com/tx/${hash}`} target="_blank" style={{ color: "#fff" }}>
                                        View on BscScan
                                    </a>
                                </Button>
                            )}
                            <br />
                            {stakePath !== "/" &&
                                <div
                                    className={cx("buy-token-airi")}
                                    onClick={() => history.push(stakePath)}>
                                    <span className={cx("buy-token-airi-text")}>Stake LP token</span>
                                </div>
                            }
                            {path !== "/" &&
                                <div
                                    className={cx("buy-token-airi")}
                                    onClick={() => history.push(path)}>
                                    <span className={cx("buy-token-airi-text")}>Lock liquidity</span>
                                </div>
                            }
                        </div>
                    </div>
                ) : stepLoading === 1 ? (
                    <div>
                        <div className={cx("center-body")}>
                            <div className={cx("icn-spinner")}>
                                <img src={loadingProcess} alt="icon-loading" />
                            </div>
                        </div>
                        <div className={cx("bottom-body")}>
                            <div className={cx("title")}>Transaction submitted</div>
                            <div className={cx("content")}>Waiting for confirmation</div>
                            <br />
                            {hash && (
                                <Button className={cx("MuiButton-root")} size="large">
                                    <a href={`https://bscscan.com/tx/${hash}`} target="_blank" style={{ color: "#fff" }}>
                                        View on BscScan
                                    </a>
                                </Button>
                            )}
                        </div>
                    </div>
                ) : stepLoading === 0 ? (
                    <div>
                        <div className={cx("center-body")}>
                            <div className={cx("icn-spinner")}>
                                <img src={loadingProcess} alt="icon-loading" />
                            </div>
                        </div>
                        <div className={cx("bottom-body")}>
                            <div className={cx("title")}>{title}</div>
                            <div className={cx("content")}>Confirm this transaction in your wallet</div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className={cx("center-body")}>
                            <img src={warning} alt="icon-warning" />
                        </div>
                        <div className={cx("bottom-body")}>
                            <div className={cx("title")}>Transaction failed</div>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default LoadingModal;
