// import { isTestnet } from "src/config";
import configEnv from '../../utils/config.env'

export const NetworkKey = {
    MAINNET: "mainnet",
    TESTNET: "testnet",
    PRERELEASE: "prerelease",
};

const networks = {
    [NetworkKey.MAINNET]: {
        chainId: "Oraichain",
        prefix: "orai",
        denom: "orai",
        coinType: 118,
        lcd: configEnv.REACT_APP_LCD || "https://lcd.orai.io",
        rpc: configEnv.REACT_APP_RPC_URL || "https://rpc.orai.io",
        id: NetworkKey.MAINNET,
        fee: { gasPrice: "0", amount: "0", gas: "2000000" }, // 0.000500 ORAI
    },
    [NetworkKey.PRERELEASE]: {
        chainId: "Oraichain-fork",
        prefix: "orai",
        denom: "orai",
        coinType: 118,
        lcd: configEnv.REACT_APP_LCD || "https://pre.lcd.orai.io",
        rpc: configEnv.REACT_APP_RPC_URL || "https://pre.rpc.orai.io",
        id: NetworkKey.PRERELEASE,
        fee: { gasPrice: "0", amount: "0", gas: "2000000" }, // 0.050000 ORAI
    },
    [NetworkKey.TESTNET]: {
        chainId: "Oraichain-testnet",
        prefix: "orai",
        denom: "orai",
        coinType: 118,
        lcd: configEnv.REACT_APP_LCD || "https://testnet-lcd.orai.io",
        rpc: configEnv.REACT_APP_RPC_URL || "https://testnet-rpc.orai.io",
        id: NetworkKey.TESTNET,
        fee: { gasPrice: "0", amount: "0", gas: "2000000" }, // 0.050000 ORAI
    },
};

export default networks;

let networkTmp;
if (configEnv.REACT_APP_LCD === "https://testnet-lcd.orai.io") {
    networkTmp = networks[NetworkKey.TESTNET];
} else if (configEnv.REACT_APP_LCD === "https://pre.lcd.orai.io") {
    networkTmp = networks[NetworkKey.PRERELEASE];
} else {
    networkTmp = networks[NetworkKey.MAINNET];
}

export const network = networkTmp
export const mobileBlacklistNetworks = [];
