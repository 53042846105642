import React, { ReactNode, useEffect } from "react";
import cn from "classnames/bind";
import _ from "lodash";
import { AlertBoxVariant } from "src/types/AlertBox";
import CheckIcon from "src/components/icons/CheckIcon";
import TimesIcon from "src/components/icons/TimesIcon";
import styles from "./AlertBox.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { hideAlertBox, selectAlert } from "src/store/slices/alertSlice";

const cx = cn.bind(styles);

const AlertBox: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const {
        isVisible,
        variant,
        title,
        message,
        onHide,
    } = useSelector(selectAlert);
    const hide = () => dispatch(hideAlertBox());
    const close = () => {
        hide();
        if (!_.isNil(onHide)) {
            onHide();
        }
    };

    useEffect(() => {
        if (isVisible) {
            const timeout = setTimeout(close, 5000);
            return () => {
                clearTimeout(timeout);
            };
        }
    });

    if (!isVisible) {
        return null;
    }

    let titleIcon;
    if (variant === "success") {
        titleIcon = <CheckIcon className={cx("title-icon", "title-icon-success")} />;
    } else if (variant === "error") {
        titleIcon = <TimesIcon className={cx("title-icon", "title-icon-error")} />;
    }

    return (
        <div className={cx("alert-box")}>
            <div className={cx("alert-box-header")}>
                <div className={cx("title")}>
                    {titleIcon}
                    <span className={cx("title-text")}>{title}</span>
                </div>
                <div className={cx("close")} onClick={close}>
                    &times;
                </div>
            </div>
            {!_.isNil(message) ? (
                <div className={cx("alert-box-body")}>
                    <div className={cx("message")}>{message}</div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default AlertBox;
