import configEnv from './config.env'
export const getWalletBalance = async (address) => {
    return await window.Wasm.get(`/cosmos/bank/v1beta1/balances/${address}/${configEnv.REACT_APP_GET_BALANCE_API_SUFFIX}`);
};

export const getWalletAIRIBalance = async (address) => {
    const query = { balance: { address: address } };

    return await window.Wasm.query(configEnv.REACT_APP_AIRI_TOKEN_CONTRACT, JSON.stringify(query));
};
