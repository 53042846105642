// @ts-nocheck

import React, { useState, useEffect } from "react";
import cn from "classnames/bind";
import styles from "./components.module.scss";
import { read, write, sign } from "src/utils/web3Service";
import GALLERY_ABI from "src/utils/abi/gallery.json";
import RELAY_ABI from "src/utils/abi/relay.json";
import { RELAY_ADDRESS } from "src/consts/address";
import Web3 from "web3";
import LoadingModal from "src/components/common/LoadingModal/LoadingModal";
import { CHAIN_ID } from "src/consts/web3";
import { useWeb3React } from "@web3-react/core";
import createNetworkOrSwitch from "src/utils/createNetworkOrSwitch";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import CountDown from "src/components/common/CountDown/CountDown";
import Countdown from 'react-countdown';
import moment from 'moment';

const cx = cn.bind(styles);

const NftCollection: React.FC<{
    account;
    galleryId;
    selectedAuction;
    balance;
    getListAuction;
    getBalance;
    onHide;
    auctionIdx;
    symbol;
    days,
    hours,
    minutes,
    seconds,
    isExpired,
}> = ({
    account,
    galleryId,
    selectedAuction,
    balance,
    getListAuction,
    getBalance,
    onHide,
    auctionIdx,
    symbol,
    isExpired,
}) => {
        const context = useWeb3React();
        const { library, chainId } = context;
        const [stepLoading, setStepLoading] = useState(0);
        const [showModalLoading, setShowModalLoading] = useState(false);
        const [loading, setLoading] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        const [hash, setHash] = useState();
        const [sellPrice, setSellPrice] = useState("");
        const [bidPrice, setBidPrice] = useState("");
        const history = useHistory();
        const [date, setDate] = useState();
        const [isNull, setIsNull] = useState(true);

        useEffect(() => {
            if (selectedAuction) {
                if (!isExpired(Number(selectedAuction[6]) + Number(selectedAuction[9]))) {
                    let date = moment(((Number(selectedAuction[6]) + Number(selectedAuction[9])) * 1000)).toDate();
                    setDate(date);
                    setIsLoading(false);
                    // console.log("moment", moment(((Number(selectedAuction[6]) + Number(selectedAuction[9])) * 1000)).format())
                    // console.log("expire", ((Number(selectedAuction[6]) + Number(selectedAuction[9])) * 1000));
                }
            }
            if (!sellPrice || !bidPrice) {
                setIsNull(true);
            }
        }, []);

        const bid = async () => {
            try {
                if (!account) {
                    return toast.error("Please connect your wallet");
                }
                if (bidPrice < selectedAuction[4] / 10 ** 18) {
                    return toast.error("Bid price must greate than starting price");
                }
                if (bidPrice > 10000) {
                    return toast.error("Bid price must less than 10,000")
                }
                if (sellPrice > 10000) {
                    return toast.error("Sell price must less than 10,000");
                }
                if (balance < bidPrice) {
                    return toast.error("Not enough balance");
                }
                setLoading(true);
                setStepLoading(0);
                setShowModalLoading(true);
                if (chainId !== CHAIN_ID) {
                    if (chainId !== CHAIN_ID) {
                        const error = await createNetworkOrSwitch();
                        if (error) {
                            throw new Error(
                                "Please change network to Binance smart chain"
                            );
                        }
                    }
                }
                const allowance = await read("allowance", galleryId, GALLERY_ABI, [
                    account,
                    galleryId,
                ]);
                if (allowance == 0) {
                    const deadline = Math.floor(Date.now() / 1000) + 30 * 60;
                    const nonce = await read("nonces", galleryId, GALLERY_ABI, [
                        account,
                    ]);

                    const contractName = await read(
                        "NAME",
                        galleryId,
                        GALLERY_ABI,
                        []
                    );
                    console.log("contract name ", contractName);

                    const EIP712Domain = [
                        { name: "name", type: "string" },
                        { name: "version", type: "string" },
                        { name: "chainId", type: "uint256" },
                        { name: "verifyingContract", type: "address" },
                    ];

                    const domain = {
                        name: contractName,
                        version: "1",
                        chainId: CHAIN_ID,
                        verifyingContract: galleryId,
                    };
                    const Data = [
                        { name: "spender", type: "address" },
                        { name: "amount", type: "uint256" },
                        { name: "deadline", type: "uint256" },
                        { name: "nonce", type: "uint256" },
                    ];

                    const message = {
                        spender: galleryId,
                        amount: Web3.utils.toWei("999999999999", "ether"),
                        deadline,
                        nonce,
                    };
                    const data = JSON.stringify({
                        types: {
                            EIP712Domain,
                            Data,
                        },
                        domain,
                        primaryType: "Data",
                        message,
                    });
                    const signature = await sign(account, data, library.provider);
                    const { r, s, v } = signature;
                    await write(
                        "approvePermit",
                        library.provider,
                        RELAY_ADDRESS,
                        RELAY_ABI,
                        account,
                        [
                            galleryId,
                            galleryId,
                            Web3.utils.toWei("999999999999", "ether"),
                            deadline,
                            nonce,
                            v,
                            r,
                            s,
                        ]
                    );
                }
                const callback = (hash) => {
                    setHash(hash);
                    setStepLoading(1);
                };

                const deadline = Math.floor(Date.now() / 1000) + 30 * 60;
                const nonce = await read("nonces", galleryId, GALLERY_ABI, [
                    account,
                ]);

                const contractName = await read("NAME", galleryId, GALLERY_ABI, []);
                console.log("contract name ", contractName);

                const EIP712Domain = [
                    { name: "name", type: "string" },
                    { name: "version", type: "string" },
                    { name: "chainId", type: "uint256" },
                    { name: "verifyingContract", type: "address" },
                ];

                const domain = {
                    name: contractName,
                    version: "1",
                    chainId: CHAIN_ID,
                    verifyingContract: galleryId,
                };

                const Data = [
                    { name: "auctionId", type: "uint256" },
                    { name: "bidValue", type: "uint256" },
                    { name: "sellPrice", type: "uint256" },
                    { name: "deadline", type: "uint256" },
                    { name: "nonce", type: "uint256" },
                ];

                const message = {
                    auctionId: auctionIdx,
                    bidValue: Web3.utils.toWei(bidPrice),
                    sellPrice: Web3.utils.toWei(sellPrice),
                    deadline,
                    nonce,
                };
                const data = JSON.stringify({
                    types: {
                        EIP712Domain,
                        Data,
                    },
                    domain,
                    primaryType: "Data",
                    message,
                });
                const signature = await sign(account, data, library.provider);
                const { r, s, v } = signature;

                await write(
                    "bidPermit",
                    library.provider,
                    RELAY_ADDRESS,
                    RELAY_ABI,
                    account,
                    [
                        galleryId,
                        auctionIdx,
                        Web3.utils.toWei(bidPrice),
                        Web3.utils.toWei(sellPrice),
                        deadline,
                        nonce,
                        v,
                        r,
                        s,
                    ],
                    callback
                );
                getListAuction();
                getBalance();
                setStepLoading(2);
                if (bidPrice == (selectedAuction[5] / 10 ** 18)) {
                    history.push(`/nft-gallery`);
                }
            } catch (error) {
                console.log(error);
                setStepLoading(3);
                toast.error(error.message || "An error occurred")
            } finally {
                setLoading(false);
            }
        };

        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
                return (
                    <CountDown
                        days={0}
                        hours={0}
                        minutes={0}
                        seconds={0}
                    />
                )
            } else {
                return (
                    <CountDown
                        days={days}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                    />
                );
            }
        };

        const handleSellPrice = (e) => {
            if (e.target.value && bidPrice) {
                setIsNull(false);
            } else {
                setIsNull(true);
            }
            setSellPrice(e.target.value);
        }

        const handleBidPrice = (e) => {
            if (e.target.value && sellPrice) {
                setIsNull(false);
            } else {
                setIsNull(true);
            }
            setBidPrice(e.target.value);
        }

        return (
            !isLoading && <div className={cx("card-content")}>
                <div className={cx("content")}>
                    <div className={cx("left")}>
                        {/* <div className={cx("title")}>Price</div> */}
                        <div className={cx("price")}>
                            <div>Starting price: {(selectedAuction[4] / 10 ** 18).toFixed(2)}{" "}{symbol}</div>
                            <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                Ending price: {(selectedAuction[5] / 10 ** 18).toFixed(2)}{" "}{symbol}
                            </div>
                            <div>Current price: {selectedAuction.highestPrice.toFixed(2)}{" "}{symbol}</div>
                            <div style={{ paddingTop: '5px' }}>Balance: {balance.toFixed(2)}{" "}{symbol}</div>
                        </div>
                    </div>
                    <div className={cx("right")} style={{ marginLeft: '6%' }}>
                        <div className={cx("title")}>Auction ends in</div>
                        <Countdown date={date} renderer={renderer} />
                    </div>
                </div>
                <div className={styles.inputGroup}>
                    <input
                        // type="number"
                        placeholder="Bid price"
                        className={cx("input")}
                        value={bidPrice}
                        onChange={(e) => {
                            handleBidPrice(e);
                        }}
                    />
                    <input
                        // type="number"
                        placeholder="Sell price"
                        className={cx("input")}
                        value={sellPrice}
                        onChange={(e) => {
                            handleSellPrice(e);
                        }}
                    />
                </div>
                <div><a
                    href={`https://exchange.pancakeswap.finance/#/swap?outputCurrency=${galleryId}`}
                    target="_blank"
                    style={{fontSize: '12px'}}
                >Swap token </a></div>

                <button className={cx("submit")} onClick={bid} disabled={loading || isNull}>
                    Bid
                </button>

                {showModalLoading && (
                    <LoadingModal
                        show={true}
                        loading={loading}
                        title="Bidding NFT"
                        stepLoading={stepLoading}
                        onHide={() => {
                            setShowModalLoading(false);
                            setHash(null);
                            setStepLoading(0);
                        }}
                        hash={hash}
                        hideParent={onHide}
                    />
                )}
            </div>
        );
    };

export default NftCollection;
