import React from "react";
import classNames from "classnames/bind";
import styles from "./NoResult.module.scss";

// image
import NoResultImage from "src/assets/images/no-result.svg";
import NoResultImageDark from "src/assets/images/no-result-dark.svg";

// theme
import ThemeSetup from "src/utils/themeSetup";

const cx = classNames.bind(styles);

const NoResult: React.FC<{ text: string }> = ({ text = "No Result" }) => {
    const { isDarkTheme } = ThemeSetup();
    return (
        <div className={cx("no-result")}>
            <img className={cx("no-result-icon")} src={isDarkTheme ? NoResultImageDark : NoResultImage} />
            <p className={cx("no-result-text")}>{text}</p>
        </div>
    );
};

export default NoResult;
