import { useSelector } from 'react-redux';
import { Denom } from 'src/consts/denom';
import { selectPrice } from 'src/store/slices/priceSlice';

export const defaultOptions = {
    significantDigits: 6,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    divisor: 1000000
};

export const formatCurrency = (num, fractionDigits = 6) => {
    let [left, right] = num.toFixed(fractionDigits).split('.');
    // number following by group 3 number then end
    left = decimalFormatter(left);
    right = right?.replace(/0+$/, '');
    if (right) {
        left += '.' + right;
    }
    return left;
};

export const currencyFormatter = (valueRaw, options) => {
    options = { ...defaultOptions, ...options };

    let value = parseFloat(valueRaw || 0.0) / options.divisor;
    if (typeof value !== 'number' || !value) value = 0.0;
    value = value.toString();

    const [currency, decimal] = value.split('.');

    let postFix = '';

    if (decimal) {
        postFix = `${options.decimalSeparator}${decimal.toString().slice(0, options.significantDigits)}`;
    }

    return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandsSeparator)}${postFix}`;
};

export const decimalFormatter = (currency) => {
    return currency.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
};

export const getContractAddressFromCurrencySymbol = (symbol) => {
    switch (symbol) {
        case Denom.AIRI:
            return '0x......';
        case Denom.ORAI:
            return '0x......';
        default:
            break;
    }
};

export const formatCurrencyDenom = (val) => {
    if (!val) {
        return 0;
    }
    return formatCurrency(
        val /
        Math.pow(10, defaultOptions.significantDigits)
    );
}
