import { createSlice } from "@reduxjs/toolkit";
import { IState } from "src/types/redux";
import { themeIds } from "src/consts/theme";

export const activeThemeSlice = createSlice({
    name: "theme",
    initialState: themeIds.LIGHT,
    reducers: {
        setActiveThemeId: (state: number, action) => {
            return action.payload;
        },
    },
});

export const { setActiveThemeId } = activeThemeSlice.actions;

export const selectThemeId = (state: IState): number => {
    return state.activeTheme;
};

export default activeThemeSlice.reducer;
