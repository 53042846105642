import React from "react";

const LockIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg className={className} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.3763 5.25H9.89019V3.5C9.89019 1.56975 8.14602 0 6.0013 0C3.85658 0 2.11241 1.56975 2.11241 3.5V5.25H1.6263C0.822598 5.25 0.167969 5.83858 0.167969 6.5625V12.6875C0.167969 13.4114 0.822598 14 1.6263 14H10.3763C11.18 14 11.8346 13.4114 11.8346 12.6875V6.5625C11.8346 5.83858 11.18 5.25 10.3763 5.25ZM3.40871 3.5C3.40871 2.21317 4.57149 1.16667 6.0013 1.16667C7.43112 1.16667 8.59389 2.21317 8.59389 3.5V5.25H3.40871V3.5ZM6.64945 9.7545V11.0833C6.64945 11.4053 6.35973 11.6667 6.0013 11.6667C5.64288 11.6667 5.35315 11.4053 5.35315 11.0833V9.7545C4.96751 9.55208 4.70501 9.17992 4.70501 8.75C4.70501 8.10658 5.2864 7.58333 6.0013 7.58333C6.71621 7.58333 7.2976 8.10658 7.2976 8.75C7.2976 9.17992 7.0351 9.55208 6.64945 9.7545Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default LockIcon;
