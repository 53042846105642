import React from "react";
import moment from "moment";
import cn from "classnames/bind";
import CenterEllipsis from "src/components/common/CenterEllipsis/CenterEllipsis";
import AvatarLink from "src/components/common/AvatarLink/AvatarLink";
import NoResult from "src/components/common/NoResult/NoResult";
import CurrencyFormatter from "src/components/common/CurrencyFormatter/CurrencyFormatter";
import styles from "./NftGalleryTransactionHistory.module.scss";
import Event from "../Event/Event";
import { Link } from "react-router-dom";
import AvatarPlaceholder from "../AvatarPlaceholder/AvatarPlaceholder";
import configEnv from "../../../utils/config.env";

const cx = cn.bind(styles);

const scanURL = configEnv.REACT_APP_SCAN_URL || "https://testnet.scan.orai.io";

const NftGalleryTransactionHistory: React.FC<{
    loading: boolean;
    forUser?: boolean;
    data: any;
    pagination?: any;
    bscTransactions?: boolean;
    isOffer?: boolean;
}> = ({ loading, data, forUser = false, pagination, isOffer, bscTransactions }) => {
    if (loading) {
        return null;
    }
    if (!data?.length) {
        return <NoResult text='No Result' />;
    }
    return (
        <>
            <table className={cx("transaction-history")}>
                <thead>
                    <tr>
                        {!isOffer && (
                            <>
                                <th>#TxHash</th>
                                <th>Event</th>
                            </>
                        )}
                        <th>{forUser ? (bscTransactions ? "Token ID" : "Asset") : "Creator"}</th>
                        <th>Price</th>
                        <th>Time UTC</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item: any) => {
                        const user = item.event === "buy" ? item.buyer : item.seller;
                        return (
                            <tr key={item.height} style={{ width: "auto" }}>
                                {!isOffer && (
                                    <>
                                        <td>
                                            <a target='_blank' href={`${scanURL}/txs/${item.txHash}`} rel='noreferrer'>
                                                <CenterEllipsis size={4} text={item.txHash} className={cx("tx-hash")} />
                                            </a>
                                        </td>
                                        <td>
                                            <Event type={item.event} />
                                        </td>
                                    </>
                                )}
                                <td>
                                    <div className={cx("avatar-item")}>
                                        <span className={cx("avatar-username")}>{item?.sender}</span>
                                    </div>
                                </td>
                                <td style={{ whiteSpace: "nowrap" }}>
                                    <div className={cx("price")}>
                                        <CurrencyFormatter isTransaction={true} amount={item.price} />
                                    </div>
                                </td>
                                <td>
                                    <div className={cx("time")}>{moment(item.timestamp).fromNow()}</div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className={cx("pagination-section")}> {pagination?.()}</div>
        </>
    );
};

export default NftGalleryTransactionHistory;
