import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import walletSlice from "src/store/slices/walletSlice";
import activeThemeSlice from "src/store/slices/activeThemeSlice";
import alertSlice from "src/store/slices/alertSlice";
import erc20WalletSlice from "src/store/slices/erc20WalletSlice";
import priceSlice from "src/store/slices/priceSlice";
import configEnv from '../utils/config.env';

const reducers = combineReducers({
    wallet: walletSlice,
    alert: alertSlice,
    erc20Wallet: erc20WalletSlice,
    price: priceSlice,
    activeTheme: activeThemeSlice,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ['wallet', 'price', 'activeTheme'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: configEnv.NODE_ENV !== "production",
    middleware: [thunk],
});

export default store;
