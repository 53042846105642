export const Denom = {
    ORAI: "ORAI",
    AIRI: "AIRI",
};

export const CHAIN = {
  ethereum: 'Ethereum',
  polygon: 'Polygon',
  solana: 'Solana',
};
