import { createSlice } from "@reduxjs/toolkit";
import { IErc20Wallet, IState } from "src/types/redux";

export const erc20WalletSlice = createSlice({
    name: "erc20Wallet",
    initialState: {
        isOpen: false,
        type: "CHOOSE_WALLET",
        isLoading: false,
    },
    reducers: {
        openDialog: (state: IErc20Wallet, action) => {
            state.isOpen = true;
            state.type = "CHOOSE_WALLET";
        },
        startConnect: (state: IErc20Wallet, action) => {
            state.isLoading = true;
        },
        closeDialog: (state: IErc20Wallet, action) => {
            state.isOpen = false;
            state.isLoading = false;
        },
        chooseWallet: (state: IErc20Wallet, action) => {
            state.type = "CHOOSE_WALLET";
        },
        connectWallet: (state: IErc20Wallet, action) => {
            state.type = "CONNECT_WALLET";
        },
        actionBack: (state: IErc20Wallet, action) => {
            state.isLoading = false;
        },
    },
});

export const {
    openDialog,
    startConnect,
    closeDialog,
    chooseWallet,
    connectWallet,
    actionBack,
} = erc20WalletSlice.actions;

export const selectErc20Wallet = (state: IState): IErc20Wallet => {
    return state.erc20Wallet;
};

export default erc20WalletSlice.reducer;
