import React from "react";

const AuctioningIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M8.92707 13.9155V15.1666H1.33334V13.9155H8.92707ZM9.2979 1.83325L14.2199 6.69891L13.3251 7.58471L12.6543 7.36326L11.0869 8.91092L14.6667 12.4497L13.7719 13.3343L10.1927 9.79547L8.67142 11.2993L8.8505 12.0075L7.95507 12.892L3.03307 8.02636L3.92849 7.14181L4.64357 7.31822L8.62649 3.38153L8.4031 2.71843L9.2979 1.83325ZM9.7453 4.04526L5.27069 8.46801L7.50768 10.68L11.9823 6.25726L9.7453 4.04526Z'
                fill='currentColor'
                stroke='currentColor'
                stroke-width='0.5'
            />
        </svg>
    );
};

export default AuctioningIcon;
