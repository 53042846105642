// @ts-nocheck

import React, { useState } from "react";
import cn from "classnames/bind";
import styles from "./components.module.scss";
import { write, read, sign } from "src/utils/web3Service";
import RELAY_ABI from "src/utils/abi/relay.json";
import GALLERY_ABI from "src/utils/abi/gallery.json";
import { RELAY_ADDRESS } from "src/consts/address";
import LoadingModal from "src/components/common/LoadingModal/LoadingModal";
import { CHAIN_ID } from "src/consts/web3";
import { useWeb3React } from "@web3-react/core";
import createNetworkOrSwitch from "src/utils/createNetworkOrSwitch";
import { toast } from "react-toastify";
import nftExample from "src/assets/images/nftExample.png";
import { useHistory } from 'react-router-dom';

const cx = cn.bind(styles);

const NftCollection: React.FC<{
    account;
    galleryId;
    getListAuction;
    getBalance;
    onHide;
    auctionIdx;
    price;
    gallery;
    selectedAuction;
    isExpired
}> = ({
    account,
    galleryId,
    getListAuction,
    getBalance,
    onHide,
    auctionIdx,
    price,
    gallery,
    selectedAuction,
    isExpired
}) => {
        const context = useWeb3React();
        const { library, chainId } = context;
        const [stepLoading, setStepLoading] = useState(0);
        const [showModalLoading, setShowModalLoading] = useState(false);
        const [loading, setLoading] = useState(false);
        const [hash, setHash] = useState();
        const history = useHistory();

        const cancel = async () => {
            try {
                setLoading(true);
                setStepLoading(0);
                setShowModalLoading(true);
                const callback = (hash) => {
                    setHash(hash);
                    setStepLoading(1);
                };
                if (chainId !== CHAIN_ID) {
                    if (chainId !== CHAIN_ID) {
                        const error = await createNetworkOrSwitch();
                        if (error) {
                            throw new Error(
                                "Please change network to Binance smart chain"
                            );
                        }
                    }
                }
                const deadline = Math.floor(Date.now() / 1000) + 30 * 60;
                const nonce = await read("nonces", galleryId, GALLERY_ABI, [
                    account,
                ]);
                const contractName = await read("NAME", galleryId, GALLERY_ABI, []);
                console.log("contract name ", contractName);

                const EIP712Domain = [
                    { name: "name", type: "string" },
                    { name: "version", type: "string" },
                    { name: "chainId", type: "uint256" },
                    { name: "verifyingContract", type: "address" },
                ];

                const domain = {
                    name: contractName,
                    version: "1",
                    chainId: CHAIN_ID,
                    verifyingContract: galleryId,
                };
                const Data = [
                    { name: "auctionId", type: "uint256" },
                    { name: "deadline", type: "uint256" },
                    { name: "nonce", type: "uint256" },
                ];
                const message = {
                    auctionId: auctionIdx,
                    deadline,
                    nonce,
                };
                const data = JSON.stringify({
                    types: {
                        EIP712Domain,
                        Data,
                    },
                    domain,
                    primaryType: "Data",
                    message,
                });
                const signature = await sign(account, data, library.provider);
                const { r, s, v } = signature;
                await write(
                    "cancelAuctionByAuctionIdPermit",
                    library.provider,
                    RELAY_ADDRESS,
                    RELAY_ABI,
                    account,
                    [galleryId, auctionIdx, deadline, nonce, v, r, s],
                    callback
                );
                getListAuction();
                getBalance();
                setStepLoading(2);
                history.push(`/nft-gallery`);
            } catch (error) {
                console.log(error);
                setStepLoading(3);

                toast.error(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className={cx("card-content")}>
                <div className={cx("content")} style={{ alignItems: 'center' }}>
                    <div className={cx("left")}>
                        {/* <div className={cx("title")}>
                            <span>Highhest bid by</span>
                            <span className={cx("name")}> Lorem Ipsum</span>
                        </div> */}
                        <div className={cx("avatar")}>
                            {/* <img src={nftExample} width="50px" height="50px" className={cx("img")} /> */}
                            <div className={cx("price")}>
                                <div className={cx("coin")}>{(selectedAuction[4] / 10 ** 18).toFixed(2)} {gallery[1]}</div>
                                <div className={cx("dollar")}>(${price ? ((selectedAuction[4] / 10 ** 18) * price).toFixed(2) : "--"})</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={cx("right")}>
                        <div className={cx("title")}>Auction ends in</div>
                        <div className={cx("time")}>
                            {isExpired(
                                Number(selectedAuction[6]) +
                                Number(selectedAuction[9]),
                            )
                                ? "Expired"
                                : formatTime(
                                    (Number(selectedAuction[6]) +
                                        Number(
                                            selectedAuction[9],
                                        )) *
                                    1000 -
                                    Date().now,
                                ) + " left"}
                            <div className={cx("oneTime")}>
                                <div className={cx("number")}>12</div>
                                <div className={cx("text")}>Days</div>
                            </div>
                            <div className={cx("oneTime")}>
                                <div className={cx("number")}>11</div>
                                <div className={cx("text")}>Hours</div>
                            </div>
                            <div className={cx("oneTime")}>
                                <div className={cx("number")}>10</div>
                                <div className={cx("text")}>Mins</div>
                            </div>
                            <div className={cx("oneTime")}>
                                <div className={cx("number")}>09</div>
                                <div className={cx("text")}>Secs</div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <button
                    className={cx("submit")}
                    onClick={cancel}
                    disabled={loading}
                    style={{ marginTop: '30px' }}
                >
                    Cancel
                </button>

                {showModalLoading && (
                    <LoadingModal
                        show={true}
                        loading={loading}
                        title="Canceling NFT"
                        stepLoading={stepLoading}
                        onHide={() => {
                            setShowModalLoading(false);
                            setHash(null);
                            setStepLoading(0);
                        }}
                        hash={hash}
                        hideParent={onHide}
                    />
                )}
            </div>
        );
    };

export default NftCollection;
