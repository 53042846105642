// @ts-nocheck
import React from 'react';

// libraries
import { useSelector } from 'react-redux';
import cn from 'classnames/bind';

// Icons
import AiriIcon from 'src/assets/icons/AiriIcon.svg';
import OraiIconDark from 'src/assets/icons/OraiIconDark.svg';
import OraiIconLight from 'src/assets/icons/OraiIconLight.svg';

// Functions and components
import { selectPrice } from 'src/store/slices/priceSlice';
import { formatCurrency } from 'src/utils/currency';
import { currencyFormatter, decimalFormatter, defaultOptions } from 'src/utils/currency';
import styles from './CurrencyFormatter.module.scss';
import ThemeSetup from 'src/utils/themeSetup';

const cx = cn.bind(styles);

const currencyMap = {
    usd: '$'
};

const DENOM = {
    ORAI: 'ORAI',
    AIRI: 'AIRI'
};

const CurrencyFormatter: React.FC<{
    amount: number | undefined;
    denom?: string;
    currency?: string;
    isTransaction?: Boolean;
    smallClassName?: string;
    hideSubCurrency?: Boolean;
    displaySmall?: boolean;
    each?: boolean;
    displayIcon?: boolean;
    flexRight?: boolean;
}> = ({
    amount,
    denom = 'ORAI',
    currency = 'usd',
    isTransaction = false,
    smallClassName = '',
    hideSubCurrency = false,
    displaySmall = true,
    each = false,
    displayIcon = true,
    flexRight = false
}) => {
    const { isDarkTheme } = ThemeSetup();
    const price = useSelector(selectPrice);
    let formattedAmount = currencyFormatter(amount);

    if (formattedAmount?.includes('e')) {
        formattedAmount = '0';
    }

    const renderAmount = () => {
        let logo =
            denom === DENOM.AIRI ? (
                <img src={AiriIcon} className={cx('price-denom-icon')} />
            ) : (
                <img src={isDarkTheme ? OraiIconLight : OraiIconDark} className={cx('price-denom-icon')} />
            );

        return (
            <span className={cx('price', flexRight && 'price-right')}>
                {displayIcon && logo}
                {formattedAmount}
                {!displayIcon && ' ' + denom}
                {each && ' each'}
            </span>
        );
    };

    return (
        <>
            <span style={{ fontSize: 13, fontWeight: 600 }}></span>
            {renderAmount()}
            {price?.[denom?.toLowerCase()]?.[currency] &&
                displaySmall &&
                !hideSubCurrency &&
                (isTransaction ? (
                    <div style={{ textAlign: smallClassName && 'center' }}>
                        <small className={smallClassName} style={{ fontSize: '14px' }}>
                            {currencyMap[currency]}
                            {amount
                                ? formatCurrency(
                                      (amount * price?.[denom?.toLowerCase()]?.[currency]) /
                                          Math.pow(10, defaultOptions.significantDigits)
                                  )
                                : '0'}
                        </small>
                    </div>
                ) : (
                    <small className={smallClassName} style={{ fontSize: '14px' }}>
                        {' '}
                        ({currencyMap[currency]}
                        {amount
                            ? formatCurrency(
                                  (amount * price?.[denom?.toLowerCase()]?.[currency]) / Math.pow(10, defaultOptions.significantDigits)
                              )
                            : '0'}
                        )
                    </small>
                ))}
        </>
    );
};

export default CurrencyFormatter;
