// @ts-nocheck

import React, { useState } from "react";
import cn from "classnames/bind";
import styles from "./components.module.scss";
import { read, write, sign } from "src/utils/web3Service";
import RELAY_ABI from "src/utils/abi/relay.json";
import { REGISTER_ADDRESS, RELAY_ADDRESS } from "src/consts/address";
import GALLERY_ABI from "src/utils/abi/gallery.json";
import FARM_VAULT_ABI from "src/utils/abi/farm-vault.json";
import Web3 from "web3";
import LoadingModal from "src/components/common/LoadingModal/LoadingModal";
import { CHAIN_ID } from "src/consts/web3";
import { useWeb3React } from "@web3-react/core";
import createNetworkOrSwitch from "src/utils/createNetworkOrSwitch";
import { toast } from "react-toastify";
import nftExample from "src/assets/images/nftExample.png";

const cx = cn.bind(styles);

const NftCollection: React.FC<{
    account;
    galleryId;
    selectedNft;
    balance;
    getListNFTs;
    getBalance;
    onHide;
    symbol;
    price;
    gallery
}> = ({
    account,
    galleryId,
    selectedNft,
    balance,
    getListNFTs,
    getBalance,
    onHide,
    symbol,
    price,
    gallery,
}) => {
        const context = useWeb3React();
        const { chainId, library } = context;
        const [stepLoading, setStepLoading] = useState(0);
        const [showModalLoading, setShowModalLoading] = useState(false);
        const [loading, setLoading] = useState(false);
        const [hash, setHash] = useState();
        const [sellPrice, setSellPrice] = useState("");
        const [isNull, setIsNull] = useState(true);

        let buy = async () => {
            try {
                if (!account) {
                    return toast.error("Please connect your wallet");
                }
                if (sellPrice <= 0) {
                    return toast.error("Invalid sell price");
                }
                if (sellPrice > 10000) {
                    return toast.error("Sell price must less than 10,000");
                }
                if (selectedNft.price > balance) {
                    return toast.error("Not enough balance");
                }
                setLoading(true);
                setStepLoading(0);
                setShowModalLoading(true);
                const callback = (hash) => {
                    setHash(hash);
                    setStepLoading(1);
                };
                if (chainId !== CHAIN_ID) {
                    if (chainId !== CHAIN_ID) {
                        const error = await createNetworkOrSwitch();
                        if (error) {
                            throw new Error(
                                "Please change network to Binance smart chain"
                            );
                        }
                    }
                }
                const allowance = await read("allowance", galleryId, GALLERY_ABI, [
                    account,
                    galleryId,
                ]);

                if (allowance === "0") {
                    const deadline = Math.floor(Date.now() / 1000) + 30 * 60;
                    const nonce = await read("nonces", galleryId, GALLERY_ABI, [
                        account,
                    ]);

                    const contractName = await read(
                        "NAME",
                        galleryId,
                        GALLERY_ABI,
                        []
                    );
                    console.log("contract name ", contractName);

                    const EIP712Domain = [
                        { name: "name", type: "string" },
                        { name: "version", type: "string" },
                        { name: "chainId", type: "uint256" },
                        { name: "verifyingContract", type: "address" },
                    ];

                    const domain = {
                        name: contractName,
                        version: "1",
                        chainId: CHAIN_ID,
                        verifyingContract: galleryId,
                    };
                    const Data = [
                        { name: "spender", type: "address" },
                        { name: "amount", type: "uint256" },
                        { name: "deadline", type: "uint256" },
                        { name: "nonce", type: "uint256" },
                    ];

                    const message = {
                        spender: galleryId,
                        amount: Web3.utils.toWei("999999999999", "ether"),
                        deadline,
                        nonce,
                    };
                    const data = JSON.stringify({
                        types: {
                            EIP712Domain,
                            Data,
                        },
                        domain,
                        primaryType: "Data",
                        message,
                    });
                    const signature = await sign(account, data, library.provider);
                    const { r, s, v } = signature;
                    await write(
                        "approvePermit",
                        library.provider,
                        RELAY_ADDRESS,
                        RELAY_ABI,
                        account,
                        [
                            galleryId,
                            galleryId,
                            Web3.utils.toWei("999999999999", "ether"),
                            deadline,
                            nonce,
                            v,
                            r,
                            s,
                        ]
                    );
                }
                const deadline = Math.floor(Date.now() / 1000) + 30 * 60;
                const nonce = await read("nonces", galleryId, GALLERY_ABI, [
                    account,
                ]);
                const contractName = await read("NAME", galleryId, GALLERY_ABI, []);
                console.log("contract name ", contractName);

                const EIP712Domain = [
                    { name: "name", type: "string" },
                    { name: "version", type: "string" },
                    { name: "chainId", type: "uint256" },
                    { name: "verifyingContract", type: "address" },
                ];

                const domain = {
                    name: contractName,
                    version: "1",
                    chainId: CHAIN_ID,
                    verifyingContract: galleryId,
                };

                const Data = [
                    { name: "nftFactory", type: "address" },
                    { name: "tokenId", type: "uint256" },
                    { name: "index", type: "uint256" },
                    { name: "sellPrice", type: "uint256" },
                    { name: "deadline", type: "uint256" },
                    { name: "nonce", type: "uint256" },
                ];

                const message = {
                    nftFactory: REGISTER_ADDRESS,
                    tokenId: parseInt(selectedNft.id),
                    index: 0,
                    sellPrice: Web3.utils.toWei(sellPrice),
                    deadline,
                    nonce,
                };
                const data = JSON.stringify({
                    types: {
                        EIP712Domain,
                        Data,
                    },
                    domain,
                    primaryType: "Data",
                    message,
                });
                const signature = await sign(account, data, library.provider);
                const { r, s, v } = signature;
                await write(
                    "buyPermit",
                    library.provider,
                    RELAY_ADDRESS,
                    RELAY_ABI,
                    account,
                    [
                        galleryId,
                        selectedNft.factory,
                        parseInt(selectedNft.id),
                        0,
                        Web3.utils.toWei(sellPrice),
                        deadline,
                        nonce,
                        v,
                        r,
                        s,
                    ],
                    callback
                );
                getListNFTs();
                getBalance();
                setStepLoading(2);
            } catch (error) {
                console.log(error);
                setStepLoading(3);
                toast.error(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        const handleSellPrice = (e) => {
            if (e.target.value) {
                setIsNull(false);
            } else {
                setIsNull(true);
            }
            setSellPrice(e.target.value);
        }

        return (
            <div className={cx("card-content")}>
                <div className={cx("content")}>
                    <div className={cx("left")}>
                        <div className={cx("title")}>
                            <span>Price:</span>
                            {/* <span className={cx("name")}> Lorem Ipsum</span> */}
                        </div>
                        <div className={cx("avatar")} style={{ marginTop: '0px' }}>
                            {/* <img src={nftExample} width="50px" height="50px" className={cx("img")} /> */}
                            <div className={cx("price")} style={{ marginLeft: '0px', marginTop: '0px' }}>
                                <div className={cx("coin")}>{selectedNft.price.toFixed(2)} {gallery[1]}</div>
                                <div className={cx("dollar")}>(${price ? (selectedNft.price * price).toFixed(2) : "--"})</div>
                                <div>Balance: {balance.toFixed(2)}{" "}{gallery[1]}</div>
                            </div>
                        </div>
                    </div>
                    <div className={cx("right")}>
                        <div className={cx("title")}>Sell price:</div>
                        <div className={cx("time")}>
                            <input
                                // type="number"
                                className={cx("input")}
                                onChange={(e) => handleSellPrice(e)}
                                value={sellPrice}
                                min={0}
                                placeholder={`Ex: 600 ${gallery._symbol}`}
                            />
                        </div>
                        <div><a
                            href={`https://exchange.pancakeswap.finance/#/swap?outputCurrency=${galleryId}`}
                            target="_blank"
                            style={{ fontSize: '12px' }}
                        >Swap token </a></div>
                    </div>
                </div>

                <button className={cx("submit")} onClick={buy} disabled={loading || isNull}>
                    Buy
                </button>

                {showModalLoading && (
                    <LoadingModal
                        show={true}
                        loading={loading}
                        title="Withdrawing NFT"
                        stepLoading={stepLoading}
                        onHide={() => {
                            setShowModalLoading(false);
                            setHash(null);
                            setStepLoading(0);
                        }}
                        hash={hash}
                        hideParent={onHide}
                    />
                )}
            </div>
        );
    };

export default NftCollection;
