import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";

import { injected } from "src/utils/connectors";
import useToken from "./token";
import { useMutate } from "restful-react";
import { useDispatch } from "react-redux";
import { setWalletInformation } from "src/store/slices/walletSlice";
import { useHistory } from "react-router-dom";
import { getWalletAIRIBalance, getWalletBalance } from "./balance";
import { notification } from "antd";
import axios from "axios";
import { isNil } from "lodash";
import { makeid } from "./string";
import configEnv from "./config.env";

export function useEagerConnect() {
    const { activate, active } = useWeb3React();

    const [tried, setTried] = useState(false);

    useEffect(() => {
        injected.isAuthorized().then((isAuthorized) => {
            if (isAuthorized) {
                activate(injected, undefined, true).catch(() => {
                    setTried(true);
                });
            } else {
                setTried(true);
            }
        });
    }, [activate]); // intentionally only running on mount (make sure it's only mounted once :))

    // if the connection worked, wait until we get confirmation of that to flip the flag
    useEffect(() => {
        if (!tried && active) {
            setTried(true);
        }
    }, [tried, active]);

    return tried;
}

export function useInactiveListener(suppress = false) {
    const { active, error, activate } = useWeb3React();

    useEffect(() => {
        const { ethereum } = window;
        if (ethereum && ethereum.on && !active && !error && !suppress) {
            const handleConnect = () => {
                console.log("Handling 'connect' event");
                activate(injected);
            };
            const handleChainChanged = (chainId) => {
                console.log("Handling 'chainChanged' event with payload", chainId);
                activate(injected);
            };
            const handleAccountsChanged = (accounts) => {
                console.log("Handling 'accountsChanged' event with payload", accounts);
                if (accounts.length > 0) {
                    activate(injected);
                }
            };
            const handleNetworkChanged = (networkId) => {
                console.log("Handling 'networkChanged' event with payload", networkId);
                activate(injected);
            };

            ethereum.on("connect", handleConnect);
            ethereum.on("chainChanged", handleChainChanged);
            ethereum.on("accountsChanged", handleAccountsChanged);
            ethereum.on("networkChanged", handleNetworkChanged);

            return () => {
                if (ethereum.removeListener) {
                    ethereum.removeListener("connect", handleConnect);
                    ethereum.removeListener("chainChanged", handleChainChanged);
                    ethereum.removeListener("accountsChanged", handleAccountsChanged);
                    ethereum.removeListener("networkChanged", handleNetworkChanged);
                }
            };
        }
    }, [active, error, suppress, activate]);
}

export function useCheckMobileScreen(currentWidth = 768) {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return width <= currentWidth;
}

export function useGetScreenWidth() {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return width;
}

export function useConnectKeplrWallet() {
    const history = useHistory();
    const { saveToken } = useToken();
    const dispatch = useDispatch();
    const walletInformation = (info) => dispatch(setWalletInformation(info));
    const { mutate: authentication, loading } = useMutate({
        verb: "POST",
        path: `/auth/signature-authentication`,
        base: configEnv.REACT_APP_BASE_URL
    });

    const connectKeplrWallet = async () => {
        try {
            const keplr = await window.Keplr.getKeplr();
            const chainId = window.Wasm.cosmos.chainId;
            console.log("chainId: ", chainId);

            if (keplr) {
                await window.Keplr.suggestChain(chainId);
            } else {
                notification.error({ message: "You must install Keplr Wallet first to continue" });
                return;
            }

            const key = await window.Keplr.getKeplrKey(chainId);
            // const isNanoLedger = key?.isNanoLedger;
            let nonce = (await axios.get(`${configEnv.REACT_APP_BASE_URL}/providers/nonce/${key.bech32Address}`))?.data?.nonce;
            if (isNil(nonce)) {
                nonce = makeid(8);
            }
            let signDoc = null;
            let response = null;
            let postData = {};
            signDoc = window.Keplr.getFixedAminoSignDoc(
                key.bech32Address,
                Buffer.from(JSON.stringify({ nonce })).toString("base64")
            );
            response = await window.keplr.signArbitrary(chainId, key.bech32Address, JSON.stringify({ nonce }));
            postData = {
                signed: signDoc,
                chain_id: chainId,
                nonce: nonce,
                keplr: true,
                username: key?.name,
            };

            console.log("key: ", key);
            console.log("nonce: ", nonce);
            console.log("signDoc: ", signDoc);
            console.log("signature: ", response.signature);
            console.log("pubkey: ", response.pub_key.value);
            console.log("response: ", response);

            const result = await authentication(postData, {
                headers: {
                    Authorization: `Bearer ${response.signature} ${response.pub_key.value} true`,
                },
            });

            const { balance } = await getWalletBalance(key.bech32Address);
            const { airiBalance } = await getWalletAIRIBalance(key.bech32Address);

            saveToken(result?.token?.accessToken);
            walletInformation({
                ...result,
                username: result.username,
                picture: result.picture,
                publicAddress: key.bech32Address,
                balance: balance?.amount ?? 0,
                airiBalance: airiBalance?.balance ?? 0,
            });
            if (history?.location?.state?.from) {
                history.push(history.location.state.from);
            } else {
                // setShow(false);
            }
        } catch (error) {
            console.log("error: ", error);
            notification.error({ message: error.message });
        }
    };

    return { connectKeplrWallet };
}
