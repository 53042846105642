// @ts-nocheck

import React, { useState, useEffect } from "react";
import cn from "classnames/bind";
import styles from "./NftGalleryDetail.module.scss";
import { Tab, Tabs } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import Buy from "./NftCardComponents/Buy";
import Withdraw from "./NftCardComponents/Withdraw";
import ChangePrice from "./NftCardComponents/ChangePrice";

const cx = cn.bind(styles);

const NftCard: React.FC<{
    selectedNft: any;
    galleryId: any;
    listNFTs;
    symbol;
    price: any;
    gallery: any;
    balance: any;
    getBalance;
    getListNFTs;
}> = ({ selectedNft, galleryId, listNFTs, symbol, price, gallery, balance, getBalance, getListNFTs }) => {
    const context = useWeb3React();
    const { account } = context;
    const [activeKey, setActiveKey] = useState("Buy");

    useEffect(() => {
        if (selectedNft) {
            const buyCondition = !isOwner(selectedNft.owner);
            if (buyCondition) {
                setActiveKey("Buy");
            } else {
                setActiveKey("Withdraw");
            }
        }
    }, [selectedNft]);

    const isOwner = (address) => {
        return account && account.toLowerCase() === address.toLowerCase();
    };

    if (!selectedNft) {
        return <div></div>;
    }

    return (
        <div className={cx("nft-card")}>
            <Tabs activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                {isOwner(selectedNft.owner) && !selectedNft.isBlockChangePrice && (
                    <Tab eventKey="Change Price" title="Change Price">
                        <ChangePrice
                            price={price}
                            selectedNft={selectedNft}
                            gallery={gallery}
                            galleryId={galleryId}
                            getListNFTs={getListNFTs}
                            balance={balance}
                            account={account}
                        />
                    </Tab>
                )}
                {!isOwner(selectedNft.owner) && (
                    <Tab eventKey="Buy" title="Buy">
                        <Buy
                            price={price}
                            selectedNft={selectedNft}
                            gallery={gallery}
                            account={account}
                            balance={balance}
                            getBalance={getBalance}
                            galleryId={galleryId}
                            getListNFTs={getListNFTs}
                        />
                    </Tab>
                )}
                <Tab eventKey="Withdraw" title="Withdraw">
                    <Withdraw
                        price={price}
                        selectedNft={selectedNft}
                        gallery={gallery}
                        account={account}
                        balance={balance}
                        getBalance={getBalance}
                        galleryId={galleryId}
                        getListNFTs={getListNFTs}
                    />
                </Tab>
            </Tabs>
        </div>
    );
};

export default NftCard;
