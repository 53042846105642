// @ts-nocheck

import React, { useState, useEffect } from "react";
import cn from "classnames/bind";
import styles from "./NftGalleryDetail.module.scss";
import { Tab, Tabs } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import Bid from "./NftCardComponents/Bid";
import Cancel from "./NftCardComponents/Cancel";
import Finish from "./NftCardComponents/Finish";
import moment from 'moment';

const cx = cn.bind(styles);

const AuctionCard: React.FC<{
    selectedAuction: any;
    galleryId: any;
    isExpired: any;
    price: any;
    gallery: any;
    symbol: any;
    selectedNft: any;
    balance: any;
    getBalance;
    getListAuction;
    auctionIdx: any;
    onHide: boolean;
    getListNFTs;
}> = ({
    selectedAuction,
    galleryId,
    isExpired,
    price,
    gallery,
    symbol,
    selectedNft,
    balance,
    getBalance,
    getListAuction,
    auctionIdx,
    onHide,
    getListNFTs,
}) => {
        const context = useWeb3React();
        const { account } = context;
        const [activeKey, setActiveKey] = useState("Bid");

        useEffect(() => {
            if (selectedAuction) {
                const bidCondition = selectedAuction[8] == 1 && !isExpired(Number(selectedAuction[6]) + Number(selectedAuction[9]));
                const cancelCondition =
                    isOwner(selectedAuction[1]) &&
                    selectedAuction[8] == 1 &&
                    (selectedAuction.firstBid === undefined || isExpired(Number(selectedAuction[6]) + Number(selectedAuction[9])));
                if (bidCondition) {
                    setActiveKey("Bid");
                } else if (cancelCondition) {
                    setActiveKey("Cancel");
                } else {
                    setActiveKey("Finish");
                }
            }
        }, [selectedAuction]);

        const isOwner = (address) => {
            return account && account.toLowerCase() === address.toLowerCase();
        };

        if (!selectedAuction) {
            return <div></div>;
        }
        const bidCondition = selectedAuction[8] == 1 && !isExpired(Number(selectedAuction[6]) + Number(selectedAuction[9]));
        const cancelCondition =
            isOwner(selectedAuction[1]) &&
            selectedAuction[8] == 1 &&
            (selectedAuction.firstBid === undefined || isExpired(Number(selectedAuction[6]) + Number(selectedAuction[9])));
        const finishCondition =
            isExpired(Number(selectedAuction[6]) + Number(selectedAuction[9])) &&
            isOwner(selectedAuction[1]) &&
            selectedAuction[8] == 1 &&
            selectedAuction.firstBid;

        return (
            <div className={cx("nft-card")}>
                <Tabs activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                    {bidCondition && (
                        <Tab eventKey="Bid" title="Auction">
                            <Bid
                                selectedAuction={selectedAuction}
                                galleryId={galleryId}
                                symbol={symbol}
                                getBalance={getBalance}
                                balance={balance}
                                account={account}
                                getListAuction={getListAuction}
                                auctionIdx={auctionIdx}
                                isExpired={isExpired}
                            />
                        </Tab>
                    )}
                    {cancelCondition && (
                        <Tab eventKey="Cancel" title="Cancel">
                            <Cancel
                                selectedAuction={selectedAuction}
                                galleryId={galleryId}
                                symbol={symbol}
                                price={price}
                                gallery={gallery}
                                isExpired={isExpired}
                                getBalance={getBalance}
                                auctionIdx={auctionIdx}
                                account={account}
                                getListAuction={getListAuction}
                            />
                        </Tab>
                    )}
                    {finishCondition && (
                        < Tab eventKey="Finish" title="Finish">
                            <Finish
                                galleryId={galleryId}
                                account={account}
                                symbol={symbol}
                                getListAuction={getListAuction}
                                getBalance={getBalance}
                                auctionIdx={auctionIdx}
                                getListNFTs={getListNFTs}
                            />
                        </Tab>
                    )}
                </Tabs>
            </div >
        );
    };

export default AuctionCard;
