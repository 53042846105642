import React from "react";
import cn from "classnames/bind";
import { createUltimatePagination } from "react-ultimate-pagination";
import styles from "./SpecPagination.module.scss";
import BackwardIcon from "src/components/icons/BackwardIcon";
import PreviousIcon from "src/components/icons/PreviousIcon";
import SliderPrevButton from "src/assets/images/landing/SliderPrevButton.png";
import SliderNextButton from "src/assets/images/landing/SliderNextButton.png";
import NextIcon from "src/components/icons/NextIcon";
import ForwardIcon from "src/components/icons/ForwardIcon";
const cx = cn.bind(styles);

const Page = (props: any) => {
    return <></>;
};

const Ellipsis = (props: any) => {
    return <></>;
};

const FirstPageLink = (props: any) => {
    return <></>;
};

const PreviousPageLink = (props: any) => {
    return (
        <button disabled={props.disabled} className={cx("page-link")} onClick={props.onClick}>
            <img src={SliderPrevButton} className={cx("page-link-icon")} />
        </button>
    );
};

const NextPageLink = (props: any) => {
    return (
        <button onClick={props.onClick} disabled={props.disabled} className={cx("page-link")}>
            <img src={SliderNextButton} className={cx("page-link-icon")} />
        </button>
    );
};

const LastPageLink = (props: any) => {
    return <></>;
};

const Wrapper = (props: any) => {
    return <div className={cx("pagination")}>{props.children}</div>;
};

const SpecPagination: React.FC<{
    currentPage: number;
    dataSize: number;
    pageSize: number;
    onChange: any;
}> = ({ currentPage, dataSize, pageSize, onChange }) => {
    const itemTypeToComponent = {
        PAGE: Page,
        ELLIPSIS: Ellipsis,
        FIRST_PAGE_LINK: FirstPageLink,
        PREVIOUS_PAGE_LINK: PreviousPageLink,
        NEXT_PAGE_LINK: NextPageLink,
        LAST_PAGE_LINK: LastPageLink,
    };
    const UltimatePagination = createUltimatePagination({
        itemTypeToComponent,
        WrapperComponent: Wrapper,
    });
    return (
        <UltimatePagination
            currentPage={currentPage}
            totalPages={dataSize < pageSize ? currentPage : currentPage + 1}
            onChange={onChange}
        />
    );
};

export default SpecPagination;
