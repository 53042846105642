import { createSlice } from "@reduxjs/toolkit";
import { IWallet, IState } from 'src/types/redux';

export const walletSlice = createSlice({
    name: "wallet",
    initialState: {
        username: "Unnamed",
        publicAddress: "0x",
        picture: "https://gateway.ipfs.airight.io/ipfs/QmW3xfzC4JwNVUn1TbE4eVoBe2VtAustBbfuzPihPw7Cz4",
        childKey: null,
        description: "",
        balance: 0,
    },
    reducers: {
        setWalletInformation: (state: IWallet, action) => {
            Object.assign(state, action.payload);
        },
    },
});

export const { setWalletInformation } = walletSlice.actions;

export const selectWallet = (state: IState): IWallet => {
    return state.wallet;
};

export default walletSlice.reducer;
