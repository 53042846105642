import React from "react";
import cn from "classnames/bind";
import BuyIcon from "src/components/icons/BuyIcon";
import SellIcon from "src/components/icons/SellIcon";
import ListTxIcon from "src/components/icons/ListTxIcon";
import LockIcon from "src/components/icons/LockIcon";
import styles from "./Event.module.scss";
import AuctioningIcon from "src/assets/icons/AuctioningIcon";
const cx = cn.bind(styles);

const Event: React.FC<{ type: string | null; className?: string }> = ({ type, className }) => {
    let eventIcon = <></>;
    let eventText = <></>;
    switch (type) {
        case "buy":
            eventIcon = <BuyIcon className={cx("event-icon")} />;
            eventText = <div className={"event-text"}>Buy</div>;
            break;
        case "sell":
            eventIcon = <SellIcon className={cx("event-icon")} />;
            eventText = <div className={"event-text"}>Selling</div>;
            break;
        case "list":
            eventIcon = <ListTxIcon className={cx("event-icon")} />;
            eventText = <div className={"event-text"}>Listing</div>;
            break;
        case "lock":
            eventIcon = <LockIcon className={cx("event-icon")} />;
            eventText = <div className={"event-text"}>Lock</div>;
            break;
        case "auction":
            eventIcon = <AuctioningIcon className={cx("event-icon")} />;
            eventText = <div className={"event-text"}>Auction</div>;
            break;
        default:
            eventText = <div className={"event-text"}>{type}</div>;
            break;
    }

    return (
        <div className={cx("event", className && className, type && "event-" + type)}>
            {eventIcon}
            {eventText}
        </div>
    );
};

export default Event;
