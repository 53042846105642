import { useSelector } from "react-redux";
import { selectThemeId } from "src/store/slices/activeThemeSlice";
import { themeIds } from "src/consts/theme";

const ThemeSetup = () => {
  const activeThemeId = useSelector(selectThemeId);
  const isDarkTheme = activeThemeId === themeIds.DARK;

  return {
    isDarkTheme: isDarkTheme,
  };
};

export default ThemeSetup;
