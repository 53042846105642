import { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setWalletInformation } from 'src/store/slices/walletSlice';

import CachedLocalStorage from '../lib/localStorage';

const initToken = '';

const useToken = () => {
    const dispatch = useDispatch();
    const [token, setTokenState] = useState(initToken);
    const interval = useRef(null);
    const windowRef = useRef(null);
    const cacheRef = useRef(new CachedLocalStorage());

    const processToken = () => {
        const currentToken = cacheRef.current.getWithExpiry('token');

        if (!currentToken) {
            dispatch(setWalletInformation({ publicAddress: '0x', picture: '', username: '', childKey: '' }));
            localStorage.removeItem('isOraiWallet');
        }
        if (token !== currentToken) {
            setTokenState(currentToken);
        }
    };

    const saveToken = useCallback(
        (newToken) => {
            cacheRef.current.setWithExpiry('token', newToken, 86400 * 1000);
            setTokenState(newToken);
        },
        [token]
    );

    const deleteToken = useCallback(() => {
        cacheRef.current.removeItem('token');
        setTokenState(null);
    }, [token]);

    useEffect(() => {
        windowRef.current = window.addEventListener('storage', (e) => {
            if (e?.key === 'token') {
                processToken();
            }
        });

        processToken();
        interval.current = setInterval(() => {
            processToken();
        }, 60000);

        return () => {
            if (interval?.current) {
                clearInterval(interval.current);
            }
            if (windowRef?.current) {
                windowRef.current.removeEventListener('storage');
            }
        };
    }, []);

    return {
        token,
        saveToken,
        deleteToken,
    };
};

export default useToken;
